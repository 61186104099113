<template>
  <div
    class="image-holder"
  >
    <img src="@/assets/png/rse/RSE-pin.png" alt="pin" class="rse-pin" ref="pin" />
    <div ref="image" class="image">
      <slot />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "PinHolder",
  methods: {
    beforeEnter() {
      gsap.set(this.$refs.pin, {
        y: "-120%",
        alpha: 0,
      });
      gsap.set(this.$refs.image, {
        y: "31.4%",
        // alpha: 0,
      });
    },
    handleEnter(i) {
      const tl = new gsap.timeline()
      tl
        .delay(i * 0.2)
        .to(this.$refs.pin, {
          y: "0%",
          alpha: 1,
          duration: 0.5,
          ease: "expo.out"
        }, 'a')
        .to(this.$refs.image, {
          y: "0%",
          duration: 0.5,
          ease: "sine.out"
        }, 'a')
    }
  }
};
</script>

<style scoped lang="scss">
.image-holder {
  overflow: visible;
  position: relative;
  // --cover-color: #{$light-blue};
  &:deep(img) {
    width: 100%;
    display: block;
  }
  .rse-pin {
    width: 0.7em;
    height: auto;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-left: -0.7em;
    margin-bottom: -0.7em;
    z-index: 1;
  }
}
.image {
  will-change: transform;
}
</style>
