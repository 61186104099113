import {
    BufferGeometry,
    BufferAttribute,
    Mesh,
    RawShaderMaterial,
    Color,
    Vector2,
} from "three";

import Device from "./Device";

import vertexShader from "./shaders/fullscreen.vert.glsl";
import fragmentShader from "./shaders/fullscreen.frag.glsl";

class Fullscreen {
    constructor(props) {
        this.init(props);
    }
    init({
        scene,
        texture
    }) {
        var geometry = new BufferGeometry();
        const vertices = new Float32Array([-1, 3, 0, -1, -1, 0, 3, -1, 0]);
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        const uv = new Float32Array([0, 2, 0, 0, 2, 0]);
        geometry.setAttribute("uv", new BufferAttribute(uv, 2));

        const gridTex = texture;
        this.material = new RawShaderMaterial({
            transparent: true,
            uniforms: {
                uColor: {
                    value: new Color(0xffffff),
                },
                map: {
                    value: gridTex,
                },
                diffuse: {
                    value: null,
                },
                uGrid: {
                    value: 0.0,
                },
                aspectDiffuse: {
                    value: 2880/1686,
                },
                uOffset: {
                    value: 0.0,
                },
                uResolution: {
                    value: new Vector2(Device.width, Device.height),
                },
                uTime: {
                    value: 0.0,
                },
            },
            vertexShader,
            fragmentShader,
        });
        this.mesh = new Mesh(geometry, this.material);
        this.mesh.matrixAutoUpdate = false;
        // this.mesh.renderOrder = 2;
        this.mesh.frustumCulled = false;
        scene.add(this.mesh);
    }
    handleRender() {
    }
    handleResize() {
        this.material.uniforms.uResolution.value.x = Device.width;
        this.material.uniforms.uResolution.value.y = Device.height;
    }
}

export default Fullscreen;