<template>
  <div class="image-container craftsmanship">
    <img src="@/assets/png/philosophy/hand_pen.png" :alt="'craftsmanship'" class="hand" />
    <img src="@/assets/png/philosophy/line.png" :alt="'craftsmanship'" class="line" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { inject } from "vue";

export default {
  mounted() {
    this.resetAnimations();
    const animation = this.handleEnter();
    const scroller = inject("scroller");
    this.st = ScrollTrigger.create({
      scroller: scroller.values,
      trigger: this.$el,
      start: "top+=100% bottom",
      end: 0,
      scrub: false,
      onToggle: self => {
        if (self.isActive) {
          animation.play();
        } else {
          animation.reverse();
        }
      }
    });
  },
  unmounted() {
    if(this.st) this.st.kill()
  },
  methods: {
    resetAnimations() {
      gsap.set(this.$el.querySelector(".hand"), {
        opacity: 0,
      });
      gsap.set(this.$el.querySelector(".line"), {
        opacity: 0,
      });
    },
    handleEnter(i) {
      const tl = new gsap.timeline({ paused: true});
      tl.fromTo(
        this.$el.querySelector(".hand"),
        {
          translateX: 65,
          translateY: -7,
          rotate: 0,
          opacity: 0,
        },
        {
          translateX: -7,
          translateY: -7,
          duration: 1.2,
          ease: "power2.out",
          delay: i * 0.2,
          opacity: 1,
        },
        "a"
      );

      tl.fromTo(
        this.$el.querySelector(".line"),
        {
          scaleX: 0,
          opacity: 0,
        },
        {
          scaleX: 1,
          opacity: 1,
          duration: 1.1,
          ease: "power2.out",
          delay: i * 0.2,
        },
        "a"
      );
      return tl;
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  width: 100%;
  margin-bottom: 72px;
  height: 200px;
  position: relative;
  overflow: visible;
  opacity: 1;

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &.craftsmanship {
    .hand {
      opacity: 1;
      transform: scale(1.1) rotate(0) translate(-7px, -7px);
      transform-origin: right;
    }

    .line {
      opacity: 1;
      transform-origin: right;
    }
  }
}
</style>
