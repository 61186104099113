<template>
  <div class="cursor-canvas-content" ref="container">
    <canvas id="cursor-canvas" ref="canvas" :data-idx="idx"></canvas>
  </div>
</template>

<script>
import gsap from "gsap";
import { state } from "@/components/PullComponent/state";
const colors = ["#ffffff", "#06ec7b", "#0068ff"];
export default {
  name: "CursorCanvas",
  props: {
    cursor: {
      type: String,
    },
  },
  data() {
    return {
      loaded: false,
      strokeStyle: "rgba(255, 255, 255, 1)",
      mouse: {
        x: -220,
        y: -220,
      },
    };
  },
  computed: {
    idx() {
      let i = state.getViewIdx(this.cursor);
      if (i < 0) {
        i = 0;
      }
      if ("updateCursor" in this && "canvas" in this) this.updateCursor({ i });
      return i;
    },
  },
  mounted() {
    this.cursorImage = null;
    this.canvas = null;
    this.ctx = null;
    this.fillStyle = colors[0];

    this.loadImage();

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("mousemove", this.handleMousemove);
    document.addEventListener("mouseleave", this.handleMouseleave);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.handleMousemove);
    document.removeEventListener("mouseleave", this.handleMouseleave);
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.canvas;
      this.handleResize();
      this.ctx = this.canvas.getContext("2d");

      this.cursorImage = this.$el.querySelector("#cursor-image-white");
      this.updateCursor({ i: state.getViewIdx(this.$route.name) });
    },
    loadImage() {
      this.image = new Image();

      this.image.onload = () => {
        this.initCanvas();
      };
      this.image.src = "/models/cursor.png";
    },
    moveCursor({ x, y }) {
      if (!this.ctx) return;
      gsap.to(this.$refs.container, { alpha: 1 });
      gsap.set(this.$refs.container, {
        x,
        y,
      });
    },
    updateCursor({ i = 0 }) {
      // var M2 = TweenMax.to('.character2',1,{repeat:-1,x:-2250,ease:SteppedEase.config(15)});
      const lerp = {
        color: this.fillStyle,
      };
      gsap.to(lerp, {
        color: colors[i],
        ease: "none",
        onUpdate: () => {
          this.fillStyle = lerp.color;
          this.render();
        },
      });
      this.render();
    },
    render() {
      if (!this.canvas) return;
      const { width, height } = this.canvas;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.ctx.globalCompositeOperation = "multiply";
      this.ctx.fillStyle = this.fillStyle;
      this.ctx.fillRect(0, 0, width, height);
      this.ctx.globalCompositeOperation = "destination-in";
      this.ctx.drawImage(this.image, 0, 0);
      this.ctx.globalCompositeOperation = "source-over";
    },
    handleMouseleave(event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        gsap.killTweensOf(this.$refs.container);
        gsap.to(this.$refs.container, { alpha: 0, overwrite: true });
      }
    },
    handleMousemove(e) {
      this.mouse = e;
      this.moveCursor(e);
    },
    handleResize() {
      this.canvas.width = 104;
      this.canvas.height = 112;
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-canvas-content {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  pointer-events: none;
  transform: scale(0.5);
  will-change: transform, opacity;
  opacity: 0;

  @include respond-to("xs-down") {
    display: none;
    visibility: hidden;
  }
}

canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(-39%) translateY(-29%);
}

img {
  top: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
</style>
