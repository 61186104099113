<template>
  <div class="sub-menu">
    <section class="menu-section atelier" v-if="name === 'LAtelier'">
      <div class="main-link-menu linkable underline riverside" @click="navigateTo('LAtelier')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.LAtelier") }}</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('LAtelier', 'about')">
        <span class="caption"><span class="caption-helper">#à-propos;</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('LAtelier', 'philosophy')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.philosophies") }}</span></span
        ><span class="text-cover" />
      </div>
    </section>
    <section class="menu-section offer" v-else-if="name === 'Offer'">
      <div class="main-link-menu linkable underline riverside" @click="navigateTo('Offer')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.offer") }}</span></span
        ><span class="text-cover" />
      </div>
      <!-- <div class="link-menu linkable" @click.stop="navigateTo('Offer', 'expertises')">
        <span
class="caption"
          ><span class="caption-helper">{{ $t("menu.expertise") }}</span></span
        ><span class="text-cover" />
      </div> -->
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Offer', 'technos')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.technos") }}</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Offer', 'actions')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.actions") }}</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Offer', 'clients')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.clients") }}</span></span
        ><span class="text-cover" />
      </div>
    </section>
    <section class="menu-section consultants" v-else-if="name === 'Consultants'">
      <div class="main-link-menu linkable underline riverside" @click="navigateTo('Consultants')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.consultants") }}</span></span
        ><span class="text-cover" />
      </div>
      <!-- <div class="link-menu linkable" @click.stop="navigateTo('Consultants', 'team')">
        <span
class="caption"
          ><span class="caption-helper">{{ $t("menu.team") }}</span></span
        ><span class="text-cover" />
      </div> -->
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Consultants', 'events')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.events") }}</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Consultants', 'community')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.community") }}</span></span
        ><span class="text-cover" />
      </div>
      <div class="link-menu linkable underline riverside" @click.stop="navigateTo('Consultants', 'RSE')">
        <span class="caption"><span class="caption-helper">{{ $t("menu.rse") }}</span></span
        ><span class="text-cover" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Menu",
  emits: ["closeMenu"],
  props: {
    name: {
      type: String,
      default: "LAtelier",
    },
  },
  methods: {
    navigateTo(routeName, query) {
      if (this.isMobileDevice) {
        this.$router.push({ name: routeName });
      } else {
        this.$router.push({ name: routeName, hash: query ? `#${query}` : "" });
      }
      if (routeName === this.$route.name) {
        // this.$nextTick(() => {
        this.$emit("closeMenu");
        // });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  // width: 80%;
  // top: 50%;
  // transform: translateY(-50%);
  // height: 50vh;
  // position: absolute;
  color: $white;
  z-index: 200;
  display: flex;
  align-items: center;
  pointer-events: initial;
}

.menu-section {
  padding: 2.6vw 1.66vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include respond-to("xs-down") {
    height: 100%;
    justify-content: center;
    padding: 0 1.66vw;
  }
  
  .linkable:before,
  .linkable:after {
    bottom: 0.2em;
  }

  &.atelier {
    color: $green;

    .linkable:before,
    .linkable:after {
      --line-color: var(--green);
    }
  }

  &.offer {
    color: $dark-blue;

    .linkable:before,
    .linkable:after {
      --line-color: var(--dark-blue);
    }
  }

  &.consultants {
    color: $white;
  }
}

.main-link-menu {
  display: inline-block;
  margin-bottom: 1em;
  font-weight: 600;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  // cursor: pointer
cursor: none;
  @include respond-to("xs-down") {
    margin-bottom: 0em;
    line-height: 1;
    // display: none;
  }
}

.link-menu {
  display: inline-block;
  position: relative;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  // cursor: pointer
cursor: none;
  @include respond-to("xs-down") {
    display: none;
  }
}

.text-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--dark-blue);
  top: 0;
  left: 0;
}
.caption {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.caption-helper {
  display: inline-block;
  white-space: nowrap;
}
.sub-menu {
  @include respond-to("xs-down") {
    display: block;
    height: 100%;
  }
}
</style>
