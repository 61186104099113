<template>
  <div class="grid" ref="grid">
    <div
      class="parallax"
      ref="parallax"
    >
      <div
        class="line horizontal"
        :style="{
          transform: `translateY(${size * i + offsetY}px)`,
        }"
        :key="i"
        v-for="i in countY"
      >
        <div class="inner">
          <div class="gradient gradient-0"></div>
          <div class="gradient gradient-1"></div>
        </div>
      </div>
    </div>
    <div
      class="line vertical"
      :style="{
        transform: `translateX(${size * i + offsetX}px)`,
      }"
      :key="i"
      v-for="i in countX"
    >
      <div class="inner">
        <div class="gradient gradient-0"></div>
        <div class="gradient gradient-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, onUnmounted } from "vue";
import gsap from "gsap";

import Device from "@/experience/Device";

export default {
  name: "Grid",
  setup() {
    const scroller = inject("scroller");
    const size = ref(0);
    const offsetX = ref(0);
    const offsetY = ref(0);
    const countX = ref(0);
    const countY = ref(0);
    const grid = ref(null);
    const parallax = ref(null);
    let scrollTween = null;

    const handleResize = () => {
      const ww = window.innerWidth;
      const width = ww - (ww >= 834 ? (0.01875 * ww * 2.0) : 0);
      const height = window.innerHeight;
      size.value = 0.23125 * width;
      offsetX.value = -(width % size.value);
      offsetY.value = -(size.value - (height % size.value)) * 0.5;
      countX.value = Math.floor(width / size.value);
      countY.value = Math.floor(height / size.value);
    };

    onMounted(() => {
      handleResize();
      window.addEventListener("resize", handleResize);

      if (Device.width >= 835) {
        scrollTween = gsap.to(parallax.value, {
          y: '31.25%',
          ease: "none",
          scrollTrigger: {
            scroller: scroller.value,
            trigger: grid.value,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });
      }
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      if (scrollTween) scrollTween.kill();
    });

    return {
      size,
      offsetX,
      offsetY,
      countX,
      countY,
      grid,
      parallax
    };
  },
};
</script>

<style scoped lang="scss">
.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  @include respond-to("xs-down") {
    left: -24px;
    right: -24px;
    width: auto;    
  }
}
.parallax {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.line {
  position: absolute;
  opacity: 1;
  &.vertical {
    top: 0;
    height: 100vh;
    width: 1px;
    .inner {
      width: 100%;
      height: 200%;
      animation-name: vertical-line;
      will-change: transform;
    }
    .gradient {
      width: 100%;
      height: 50%;
      &:before {
        top: 0;
        opacity: #{0.1};
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: var(--green);
      }
      &:after {
        top: 50%;
        opacity: #{0.5 * 0.1};
      }
      &.gradient-1 {
        top: 50%;
      }
    }
  }
  &.horizontal {
    left: 0;
    width: 100vw;
    height: 1px;
    .inner {
      width: 200%;
      height: 100%;
      animation-name: horizontal-line;
      will-change: transform;
    }
    .gradient {
      width: 50%;
      height: 100%;
      &:before {
        left: 0;
        opacity: #{0.1};
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        background-color: var(--green);
      }
      &:after {
        left: 50%;
        opacity: #{0.5 * 0.1};
      }
      &.gradient-1 {
        left: 50%;
      }
    }
  }
}
.line:first-child .inner {
  animation-delay: 2.8s;
}
.line:nth-child(2) .inner {
  animation-delay: 5.6s;
}
.line:nth-child(3) .inner {
  animation-delay: 0.4s;
}
.line:nth-child(4) .inner {
  animation-delay: 3.2s;
}
.line:nth-child(5) .inner {
  animation-delay: 6s;
}
.line:nth-child(6) .inner {
  animation-delay: 0.8s;
}
.line:nth-child(7) .inner {
  animation-delay: 3.6s;
}
.line:nth-child(8) .inner {
  animation-delay: 6.4s;
}
.line:nth-child(9) .inner {
  animation-delay: 1.2s;
}
.line:nth-child(10) .inner {
  animation-delay: 4s;
}
.line .inner {
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.line .gradient,
.line .inner {
  position: absolute;
  top: 0;
  left: 0;
}
.line .gradient {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@keyframes vertical-line {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}
@keyframes horizontal-line {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>
