<template>
  <div class="split-lines">
    <slot class="slot" />
  </div>
</template>

<script>
import gsap from "gsap";
import SplitText from "gsap/SplitText";

export default {
  name: "SplitTextCover",
  methods: {
    beforeEnter() {
      gsap.set(this.$el.children, {
        opacity: 0
      });
    },
    handleEnter(i) {
      this.splits = new SplitText(this.$el.children, { type: "lines", linesClass: "word" });
      gsap.set(this.splits.lines, {
        y: "130%",
        opacity: 0
      })
      gsap.set(this.$el.children, {
        opacity: 1
      });
      const tl = new gsap.timeline(() => {
        this.splits.revert();
      });
      const stagger = 0.0628;
      tl
        .delay(i * 0.2)
        .to(this.splits.lines, {
          y: '0%',
          opacity: 1,
          duration: 0.75,
          stagger,
          // ease: "expo.inOut"
        }, "a")
    }
  }
};
</script>

<style scoped lang="scss">
</style>
