<template>
  <swiper
    class="swiper-container sub-swiper"
    slides-per-view="auto"
    :snap-grid="true"
    @snapGridLengthChange="snapGridLengthChange"
    :speed="628"
    :modules="modules"
    :mousewheel="{ forceToAxis: true }"
  >


    <swiper-slide class="swiper-slide">
      <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/meetup/1.png" alt="image meetups" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/meetup/2.png" alt="image meetups" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/meetup/3.png" alt="image meetups" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/meetup/4.png" alt="image meetups" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <p class="texte-p3 main-paragraph">
      {{$t('events.meetupsDescription')}}
    </p>
  </swiper>
</template>

<script>
import { Mousewheel } from "swiper";
import SubSwiper from "@/mixins/SubSwiper";
export default {
  name: "ContentMeetups",
  mixins: [SubSwiper],
  setup() {
    return {
      modules: [Mousewheel]
    }
  },
};
</script>

<style scoped lang="scss"></style>
