<template>
  <div class="image-container devops">
    <div class="circle circle-1">
      <img class="outside" src="@/assets/png/philosophy/cerlce-e-1.png" alt="devops" />
      <img src="@/assets/png/philosophy/cerlce-i-1.png" alt="devops" />
    </div>
    <div class="circle circle-2">
      <img class="outside" src="@/assets/png/philosophy/cerlce-e-2.png" alt="devops" />
      <img src="@/assets/png/philosophy/cerlce-i-2.png" alt="devops" />
    </div>
    <div class="circle circle-3">
      <img class="outside" src="@/assets/png/philosophy/cerlce-e-3.png" alt="devops" />
      <img src="@/assets/png/philosophy/cerlce-i-3.png" alt="devops" />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { inject } from "vue";

export default {
  mounted() {
    this.resetAnimations();
    const animation = this.handleEnter();
    const scroller = inject("scroller");
    this.st = ScrollTrigger.create({
      scroller: scroller.value,
      trigger: this.$el,
      start: "top+=100% bottom",
      end: 0,
      scrub: false,
      onToggle: self => {
        if (self.isActive) {
          animation.play();
        } else {
          animation.reverse();
        }
      }
    });
  },
  unmounted() {
    if(this.st) this.st.kill()
  },
  methods: {
    resetAnimations() {
      gsap.set(this.$el.querySelectorAll(".circle img"), {
        opacity: 0,
      });
    },
    handleEnter(i) {
      const tl = new gsap.timeline({ paused: true});
      tl.fromTo(
        this.$el.querySelectorAll(".circle img"),
        {
          rotate: -180,
          opacity: 0,
        },
        {
          rotate: 0,
          duration: 1.2,
          ease: "power2.out",
          delay: i * 0.2,
          opacity: 1,
        },
        "a"
      );
      return tl;
    },
  },
};
</script>

<style scoped lang="scss">
#philosophy {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-top: 5vh;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: $blue;
}

.subtitle {
  color: $green;
  width: 100%;
  margin-bottom: 100px;
}

.tertiary-title {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 20px;
  color: $white;
  font-weight: 600;
}

.paragraph {
  color: $white;
}

.image-container {
  width: 100%;
  margin-bottom: 72px;
  height: 200px;
  position: relative;
  overflow: visible;
  opacity: 1;

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &.devops {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .circle {
      opacity: 1;
      $radius: 70%;
      position: absolute;
      width: $radius;
      height: $radius;

      img {
        position: absolute;
        width: 60%;

        &.outside {
          transform: scale(1.2);
          transform-origin: center;
        }
      }

      &.circle-1 {
        margin-bottom: 30%;
        margin-left: 20%;
      }

      &.circle-2 {
        margin-left: 50%;
        margin-top: 20%;
      }

      &.circle-3 {
        margin-right: 10%;
        margin-top: 20%;
      }
    }
  }
}

.philosophy {
  width: 25%;
}

@media (max-width: $layout-breakpoint-medium) {

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &.devops {
      padding-left: 9%;
    }
  }
}
</style>
