<template>
  <div class="not-found">
    <div
      class="sparkle"
      :class="`sparkle-animation-${Math.ceil(Math.random() * 10) + 1}`"
      :id="`sparkle-${i}`"
      v-for="i in sparkleCount"
      :key="i"
    ></div>

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1295.92 214.1">
      <path
        id="path"
        d="M1295.9,214.1H0.5v-8.4h448.8V61.1L393,141.8h50.6v30.3h-87v-28.5L456.4,0.5h30.9v141.3h30.3v30.3h-30.3v33.7
        h91.2c-1.5-0.7-2.9-1.5-4.3-2.4c-6.7-4.4-12.6-10.1-17.2-16.6c-13.1-18.2-19.7-44.7-19.7-79.5c0-22.6,2.7-41.7,8.2-57.3l30.4,30.6
        c-0.9,8.8-1.3,17.5-1.3,26.3c0,27,3.1,46.7,9.4,59.1s16.2,18.6,29.7,18.6s22.8-6.5,29.7-18.6c6.1-10.8,9.4-34.6,9.4-59.1
        c0-26.8-3.1-46.3-9.4-58.4c-6.3-12.1-16.2-18.2-29.7-18.2s-23.4,6.1-29.7,18.3c-3.1,6.1-5.5,14-7.1,23.7l-28.9-29.1
        c2.3-5.5,5.3-10.7,8.7-15.6c13-18.2,32-27.3,56.9-27.3c24.9,0,43.8,9.1,56.9,27.3c13,18.2,19.6,44.6,19.6,79.2
        c0,35-6.6,61.6-19.7,79.8c-5.6,7.9-12.8,14.4-21.2,19l159.9-0.1V61.1L753,141.8h50.7v30.3h-87.2v-28.5L816.3,0.5h30.9v141.3h30.3
        v30.3h-30.3v33.6h448.7"
      />
    </svg>

    <div class="title">
      {{ $t("pageNotFound") }}
    </div>

    <div class="link-container">
      <router-link :to="{ name: 'LAtelier' }" class="link">
        {{ $t("backToHome") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

export default {
  name: "NotFound",
  data() {
    return {
      sparkleCount: 20,
    };
  },
  mounted() {
    gsap.registerPlugin(MotionPathPlugin);

    this.generateSparklePath();

    window.addEventListener("resize", this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.generateSparklePath();
    },
    generateSparklePath() {
      for (let i = 1; i <= this.sparkleCount; i++) {
        const sparkleId = `sparkle-${i}`;

        gsap.to(`#${sparkleId}`, {
          duration: 50,
          ease: "none",
          repeat: -1,
          delay: gsap.utils.random(-50, 0, 0.4),
          motionPath: {
            path: "#path",
            align: "#path",
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: 100vh;
  background: $dark-blue;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    background: linear-gradient(
      90deg,
      $dark-blue 0%,
      $dark-blue 5%,
      rgba($blue, 0) 30%,
      rgba($blue, 0) 70%,
      $dark-blue 95%,
      $dark-blue 100%
    );
  }

  svg {
    width: auto;
    margin: 15vh auto 0;
    overflow: visible;

    #path {
      fill: none;
      stroke: $light-blue;
      stroke-width: 2px;
    }
  }

  .sparkle {
    width: 12px;
    height: 4px;
    border-radius: 4px;
    background: $green;
    box-shadow: 0 0 16px 0px $green;

    @for $i from 1 through 10 {
      &.sparkle-animation-#{$i} {
        animation: sparkeling-#{$i} #{random(2) + 1}s linear #{random(1)}s infinite;
      }
    }
  }

  .title {
    color: #f2f2f2;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: 64px auto 0;
  }
  .link-container {
    width: 100%;
    display: flex;
    align-self: center;
    margin: 32px auto 24px;
    justify-content: center;
  }

  .link {
    color: $green;

    &:after {
      background: $green;
    }
  }
}
@for $i from 1 through 10 {
  @keyframes sparkeling-#{$i} {
    0% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
    10% {
      background: mix($green, $white, random(80));
      box-shadow: 0 0 16px 2px mix($green, $white, random(80));
    }
    20% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
    30% {
      background: mix($green, $white, random(80));
      box-shadow: 0 0 16px 2px mix($green, $white, random(80));
    }
    40% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
    50% {
      background: mix($green, $white, random(80));
      box-shadow: 0 0 16px 2px mix($green, $white, random(80));
    }
    60% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
    70% {
      background: mix($green, $white, random(80));
      box-shadow: 0 0 16px 2px mix($green, $white, random(80));
    }
    80% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
    90% {
      background: mix($green, $white, random(80));
      box-shadow: 0 0 16px 2px mix($green, $white, random(80));
    }
    100% {
      background: $green;
      box-shadow: 0 0 16px 0px $green;
    }
  }
}
</style>