<template>
  <div class="actions">
    <div
      class="action unit"
      :class="[action.letter, { active: true }]"
      v-for="(action, i) in actions"
      :key="action.name"
    >
      <div class="illustration"></div>
      <TextCover
        class="array-behave"
        ref="array-behaveactiontitle"
        data-behavior="actiontitle"
        :data-idx="i"
        :style="{
          'margin-bottom': '1em'
        }"
      >
        <h3 class="titre-h2">
          {{ $t(`actions.${action.name}Title`) }}
        </h3>
      </TextCover>
      <Alpha class="array-behave" ref="array-behaveactionsubtitle" data-behavior="actionsubtitle" :data-idx="i">
        <h4 class="legend texte-p3 no-margin">
          {{ $t(`actions.${action.name}SubTitle`) }}
        </h4>
      </Alpha>

      <Alpha
        class="array-behave"
        ref="array-behaveactiondescription"
        data-behavior="actiondescription"
        :data-idx="i"
      >
        <i18n-t class="paragraph texte-p1" tag="p" :keypath="`actions.${action.name}Description`" scope="global">
          <br />
        </i18n-t>
      </Alpha>
    </div>
  </div>
</template>

<script>
// import { gsap } from "gsap";

export default {
  name: "Actions",
  data() {
    return {
      actions: [
        {
          letter: "a",
          name: "dev",
        },
        {
          letter: "b",
          name: "consulting",
        },
        {
          letter: "c",
          name: "training",
        },
        {
          letter: "d",
          name: "coaching",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">


:deep(.paragraph) {
  .mission-list {
    padding-left: 40px;
    margin-top: 12px;

    li {
      margin-bottom: 6px;
    }
  }
}
.actions {
  display: grid;
  margin-top: 6.14vw;
  grid-template-columns: repeat(10, 1fr);
  column-gap: var(--grid-gutter);
  row-gap: 3.14vw;
  @include respond-to("xs-down") {
    grid-template-columns: minmax(0, 1fr);
  }

  .action {
    grid-column: auto / span 4;
    @include respond-to("xs-down") {
      grid-column: 1;
    }
    &:nth-child(even) {
      grid-column: 6 / span 4;
      @include respond-to("xs-down") {
        grid-column: 1;
      }
    }

    .illustration {
      width: 54px;
      height: 54px;
      @include respond-to("xs") {
        height: 0;
        width: 20%;
        padding-bottom: 20%;
      }
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
      z-index: 1;
      pointer-events: none;
      margin-bottom: 20px;
    }

    &.active {
      .titre-h2 {
        // color: var(--light-blue);
        color: var(--green);
      }
      .legend {
        color: var(--green);
        font-family: var(--title-font);
        font-style: italic;
        margin-bottom: -1em;
        // color: var(--light-blue);
      }

      &.a {
        .illustration {
          background-image: url("~@/assets/png/actions/icon_developpement_green.png");
        }
      }
      &.b {
        .illustration {
          background-image: url("~@/assets/png/actions/icon_expertise_green.png");
        }
      }
      &.c {
        .illustration {
          background-image: url("~@/assets/png/actions/icon_formation_green.png");
        }
      }
      &.d {
        .illustration {
          background-image: url("~@/assets/png/actions/icon_coaching_green.png");
        }
      }
    }
  }
}
</style>
