<template>
  <div
    class="client"
    :style="{ backgroundImage: `url(${client.logo})` }"
    role="img"
    :title="client.name"
    :aria-label="client.name"
    @mouseenter="handleMouseenter"
  >
    <div class="pixel" :class="{ green: Math.random() > 0.5, white: Math.random() > 0.35 }" v-for="i in 8 * 4" :key="i">
      <span class="pixel-helper" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "Client",
  props: {
    client: Object,
    idx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hoverable: false,
    };
  },
  unmounted() {
    if(this.hoverTl) this.hoverTl.kill();
  },
  methods: {
    handleMouseenter() {
      if (this.hoverable) {
        const $pixels = this.$el.querySelectorAll(".pixel");
        const stagger = 0.45;
        this.hoverTl = new gsap.timeline();
        this.hoverTl.to(
          $pixels,
          {
            alpha: 1,
            duration: 0.314,
            stagger: {
              amount: stagger,
              from: "random",
            },
            ease: "none",
          },
          "a"
        )
        .to(
          $pixels,
          {
            delay: 0.628,
            alpha: 0,
            duration: 0.314,
            stagger: {
              amount: stagger,
              from: "random",
            },
            ease: "none",
          },
          "a"
        );
      }
    },
    beforeEnter() {
      gsap.to(this.$el.querySelectorAll(".pixel-helper"), {
        alpha: 0,
      });
    },
    handleEnter(i) {
      const $pixels = this.$el.querySelectorAll(".pixel");
      const tl = new gsap.timeline({
        onComplete: () => {
          gsap.set($pixels, { backgroundColor: "none" });
          this.hoverable = true;
        },
      });
      const stagger = 0.45;
      tl.delay(i * 0.1618)
        .to(
          this.$el.querySelectorAll(".pixel-helper"),
          {
            alpha: 1,
            duration: 0.314,
            stagger: {
              amount: stagger,
              from: "random",
            },
            ease: "none",
          },
          "a"
        )
        .to(
          $pixels,
          {
            delay: 0.628,
            alpha: 0,
            duration: 0.314,
            stagger: {
              amount: stagger,
              from: "random",
            },
            ease: "none",
          },
          "a"
        );
    },
  },
};
</script>

<style scoped lang="scss">
.client {
  width: calc(8 * 16px);
  height: calc(4 * 16px);

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 4% 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .pixel {
    opacity: 1;
    width: 16px;
    height: 16px;
    background-color: white;
    .pixel-helper {
      display: block;
      width: 100%;
      height: 100%;
      background: $blue;
      opacity: 1;
    }

    &.green {
      .pixel-helper {
        background: $green;
      }
    }

    &.white {
      .pixel-helper {
        background: $white;
      }
    }
  }
}
</style>
