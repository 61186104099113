<template>
  <swiper
    class="swiper-container sub-swiper"
    slides-per-view="auto"
    :snap-grid="true"
    @snapGridLengthChange="snapGridLengthChange"
    :speed="628"
    :modules="modules"
    :mousewheel="{ forceToAxis: true }"
  >
    <swiper-slide class="swiper-slide">
       <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/techdays-2.webp" alt="image techdays" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
       <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/techdays-3.webp" alt="image techdays" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
       <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/techdays-4.webp" alt="image techdays" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
       <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/techdays-1.webp" alt="image techdays" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <p class="texte-p3 main-paragraph">
      {{$t('events.techdaysDescription')}}
    </p>
  </swiper>
</template>

<script>
import { Mousewheel } from "swiper";
import SubSwiper from "@/mixins/SubSwiper";
export default {
  name: "ContentTechdays",
  mixins: [SubSwiper],
  setup() {
    return {
      modules: [Mousewheel]
    }
  },
};
</script>

<style scoped lang="scss">
</style>
