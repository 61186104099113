<template>
  <i18n-t class="titre-h1" tag="h2" keypath="expertises.title" scope="global">
    <template #newLine>
      <br />
    </template>
    <template #circle>
      <span class="circle" @mouseover="enterSurround(true)">
        {{ $t("expertises.theExpertise") }}
        <Circle />
      </span>
    </template>
    <template #line1>
      <span class="line excellence" @mouseover="enterLineExcellence(true)">
        {{ $t("expertises.excellence") }}
        <ExcellenceLine />
      </span>
    </template>
    <template #line2>
      <span class="line technique" @mouseenter="enterLineTechnique(true)">
        {{ $t("expertises.technique") }}
        <TechniqueLine />
      </span>
    </template>
  </i18n-t>
</template>

<script>
import gsap from "gsap";
import Circle from "@/assets/svg/surround.svg";
import ExcellenceLine from "@/assets/svg/underline-excellence.svg";
import TechniqueLine from "@/assets/svg/underline-technique.svg";
import ScrollTrigger from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

export default {
  name: "Heading",
  components: {
    Circle,
    ExcellenceLine,
    TechniqueLine,
  },
  created() {
    gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);
  },
  mounted() {
    this.resetAnimation();
    this.enterSurround();
    this.enterLineTechnique();
    this.enterLineExcellence();
  },
  methods: {
    resetAnimation() {
      gsap.to(this.$el.querySelector(".circle svg path"), {
        drawSVG: "0%",
        duration: 0,
      });
      gsap.to(this.$el.querySelector(".line.technique svg path"), {
        drawSVG: "0%",
        duration: 0,
      });
      gsap.to(this.$el.querySelector(".line.excellence svg path"), {
        drawSVG: "100% 100%",
        duration: 0,
      });
    },
    enterSurround(hover) {
      const timeline = gsap.timeline();
      timeline.to(this.$el.querySelector(".circle svg path"), {
        drawSVG: "0%",
      });
      timeline.to(this.$el.querySelector(".circle svg path"), {
        drawSVG: "100%",
        opacity: 1,
        duration: 0.4,
        delay: (hover ? 0.2 : 1.2) * (Math.random() * 0.4 + 0.8),
        ease: "cubic-bezier(0.36, 0, 0.66, -0.56)",
      });
    },
    enterLineExcellence(hover) {
      const timeline = gsap.timeline();
      timeline.to(this.$el.querySelector(".line.excellence svg path"), {
        drawSVG: "100% 100%",
      });
      timeline.to(this.$el.querySelector(".line.excellence svg path"), {
        drawSVG: "100% 0%",
        opacity: 1,
        duration: 0.5,
        delay: (hover ? 0.2 : 1.5) * (Math.random() * 0.4 + 0.8),
        ease: " cubic-bezier(0.36, 0, 0.66, -0.56)",
      });
    },
    enterLineTechnique(hover) {
      const timeline = gsap.timeline();
      timeline.to(this.$el.querySelector(".line.technique svg path"), {
        drawSVG: "0%",
      });
      timeline.to(this.$el.querySelector(".line.technique svg path"), {
        drawSVG: "100%",
        opacity: 1,
        duration: 0.3,
        delay: (hover ? 0.2 : 2) * (Math.random() * 0.4 + 0.8),
        ease: "cubic-bezier(0.36, 0, 0.66, -0.56)",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.titre-h1 {

  span {
    position: relative;
    z-index: 0;

    &.line {
      width: max-content;
      display: inline;

      svg {
        position: absolute;
        left: 0;
        bottom: -0.1em;
        opacity: 0.5;
        pointer-events: none;
        right: 0;
        width: 100%;
      }
    }

    &.circle {
      svg {
        position: absolute;
        left: 45%;
        height: 180%;
        top: 50%;
        width: 120%;
        opacity: 0.5;
        pointer-events: none;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}
//   .titre-h1 {
//     margin-bottom: 80px;
//     span {
//       &.circle {
//         svg {
//           height: 60px;
//           left: -4px;
//           right: 0;
//           bottom: -14px;
//           width: 152px;
//         }
//       }
//     }
//   }
</style>
