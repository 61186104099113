<template>
  <!-- <CookieBanner v-if="!cookiesValidated && !$route.meta.noCookieBanner" /> -->
  <MenuButton @toggleMenu="toggleMenu" :menuOpen="menuOpen" v-if="!$route.meta.noOverlays && loaded" />
  <DecorationGabarit
    ref="decorationGabarit"
    class="loading"
    @update-highlight="updateHighlight"
    :locked="menuOpen || !lateralNav"
    :slots="slots"
    :cursor="cursor"
  >
    <template :key="name" v-for="{ name, label, color } in slots" v-slot:[name]>
      <router-view class="sub-view" :name="name" v-slot="{ Component }">
        <Transition @enter="handleSubViewOpen" @leave="handleSubViewLeave" :css="false">
          <component :data-name="name" :is="Component" :ref="`subview-${name}`" v-if="loaded && !menuOpen" />
        </Transition>
      </router-view>
      <Transition @enter="handleOpenSubMenu" @leave="handleLeaveSubMenu" :css="false">
        <Menu :name="name" v-if="menuOpen" @closeMenu="closeMenu" />
      </Transition>
      <Transition @enter="handleEnterLateral" @leave="handleLeaveLateral" :css="false">
        <router-link
          class="lateral-nav-link titre-h3"
          v-if="!menuOpen && $route.name !== name && lateralNav && highlight === name"
          :to="{ name: name }"
        >
          <span class="caption-container">
            <span class="caption">
              <span
                class="caption-helper"
                :style="{
                  color: `var(${color})`,
                }"
                >{{ label }}</span
              >
            </span>
            <span class="cover" />
          </span>
        </router-link>
      </Transition>
    </template>
  </DecorationGabarit>

  <Transition @enter="handleMenuOpen" @leave="handleMenuLeave" :css="false">
    <MenuOverlay :slots="slots" v-if="menuOpen" @closeMenu="closeMenu" />
  </Transition>
  <CursorCanvas :cursor="cursor" />
</template>

<script>
import { mapGetters } from "vuex";
import { gsap } from "gsap";
import Flip from "gsap/Flip";

import Contact from "@/components/Contact";
import Menu from "@/components/Menu";
import MenuOverlay from "@/components/MenuOverlay";
import MenuButton from "@/components/MenuButton";
import CursorCanvas from "@/components/CursorCanvas.vue";
import DecorationGabarit from "@/components/DecorationGabarit";
import PullComponent from "@/components/PullComponent/index";
import { state } from "@/components/PullComponent/state";

import { LoadingAnimation, openView, closeView, resetView } from "@/composable/Animations";

const defaultDuration = 0.625;
const subviewDuration = 1.0;
export default {
  name: "App",
  components: {
    DecorationGabarit,
    Contact,
    Menu,
    MenuOverlay,
    MenuButton,
    PullComponent,
    CursorCanvas
  },
  data() {
    return {
      slots: state.slots,
      transitionName: "",
      menuOpen: false,
      loaded: false,
      ignoreSubViewOpenFlip: false,
      lateralNav: false,
      highlight: "default",
      cursor: "default"
    };
  },
  computed: {
    ...mapGetters(["isMobileDevice", "cookiesValidated"]),
  },
  mounted() {
    this.loadingEnter();
    this.$router.beforeEach((to, from, next) => {
      const direction = Math.sign(state.getViewIdx(to.name) - state.getViewIdx(from.name));
      state.updateDirection(direction);
      next();
    });

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    "$route.name"(newVal) {
      if (this.menuOpen) {
        this.cleanUpRouteClasses();
        this.menuOpen = false;
      }
      this.cursor = newVal;
    },
  },
  methods: {
    cleanUpRouteClasses(name = this.$route.name) {
      const { decorationGabarit } = this.$refs;
      decorationGabarit.$el.classList.remove(`section-LAtelier`);
      decorationGabarit.$el.classList.remove(`section-Offer`);
      decorationGabarit.$el.classList.remove(`section-Consultants`);
      decorationGabarit.$el.classList.add(`section-${name}`);

      decorationGabarit.$el.dataset.highlight = null;
    },
    updateHighlight(value) {
      const { decoration, gabarit } = this.openDecoration();
      const { decorationGabarit } = this.$refs;
      decorationGabarit.$el.dataset.highlight = value;
      this.highlight = value;
      const tl = gsap.timeline();
      tl.add(this.animateDecoration({ decoration, gabarit, duration: 1 }), "a");
    },
    loadingEnter() {
      const { decorationGabarit } = this.$refs;
      const animation = new LoadingAnimation({
        decorationGabarit,
        openDecoration: this.openDecoration,
        animateDecoration: this.animateDecoration
      });

      const tl = gsap.timeline({
        onComplete: () => {
          this.loaded = true;
          this.lateralNav = false;
        },
      });
      tl.add(animation);
    },
    handleOpenSubMenu(el, done) {
      const tl = gsap.timeline({
        onComplete: done,
      });
      gsap.set(el.querySelectorAll(".linkable .caption"), {
        x: "-100%",
      });
      gsap.set(el.querySelectorAll(".linkable .caption-helper"), {
        x: "100%",
      });
      tl.delay(0.625)
        .to(
          el.querySelectorAll(".linkable .caption"),
          {
            x: "0%",
            duration: 1,
            stagger: {
              amount: 0.15,
            },
            ease: "expo.inOut",
          },
          "a"
        )
        .to(
          el.querySelectorAll(".linkable .caption-helper"),
          {
            x: "0%",
            duration: 1,
            stagger: {
              amount: 0.15,
            },
            ease: "expo.inOut",
          },
          "a"
        )
        .fromTo(
          el.querySelectorAll(".linkable .text-cover"),
          {
            x: "-100%",
          },
          {
            x: "100%",
            duration: 1,
            stagger: {
              amount: 0.15,
            },
            ease: "expo.inOut",
          },
          "a"
        )
        .set(el.querySelectorAll(".linkable .text-cover"), {
          autoAlpha: 0,
        });
    },
    handleLeaveSubMenu(el, done) {
      const tl = gsap.timeline({
        onComplete: done,
      });
      gsap.killTweensOf(el.querySelectorAll(".linkable .text-cover"));
      tl.set(
        el.querySelectorAll(".linkable .text-cover"),
        {
          autoAlpha: 1,
        },
        "a"
      )
        .to(
          el.querySelectorAll(".linkable .caption"),
          {
            x: "100%",
            duration: 0.625,
            stagger: {
              amount: 0.1,
            },
            ease: "expo.inOut",
          },
          "a"
        )
        .to(
          el.querySelectorAll(".linkable .caption-helper"),
          {
            x: "-100%",
            duration: 0.625,
            stagger: {
              amount: 0.1,
            },
            ease: "expo.inOut",
          },
          "a"
        )
        .fromTo(
          el.querySelectorAll(".linkable .text-cover"),
          {
            x: "-100%",
          },
          {
            x: "100%",
            duration: 0.625,
            stagger: {
              amount: 0.1,
            },
            ease: "expo.inOut",
          },
          "a"
        );
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    openDecoration() {
      const { decorationGabarit } = this.$refs;

      const decoration = Flip.getState(decorationGabarit.$el.querySelectorAll(".decoration"));
      const gabarit = Flip.getState(decorationGabarit.$el);
      return {
        decoration,
        gabarit,
      };
    },
    animateDecoration({ decoration, gabarit, duration = defaultDuration, ease = "expo.out" }) {
      const tl = new gsap.timeline();
      tl.add(
        Flip.from(decoration, {
          absolute: true,
          duration,
          ease,
        }),
        "a"
      ).add(
        Flip.from(gabarit, {
          absolute: true,
          duration,
          scale: true,
          ease,
        }),
        "a"
      );

      return tl;
    },
    openView,
    closeView,
    resetView,
    handleMenuOpen(el, done) {
      this.lateralNav = false;
      const { decorationGabarit } = this.$refs;

      const { decoration, gabarit } = this.openDecoration();
      decorationGabarit.$el.classList.add("menu-open");

      const tl = gsap.timeline({
        onStart: () => {
          this.cursor = "default";
        },
        onComplete: () => {
          this.lateralNav = false;
          done();
        },
      });
      gsap.killTweensOf(el);
      tl.add(this.animateDecoration({ decoration, gabarit, duration: defaultDuration * 2 }), "b");
    },
    handleMenuLeave(el, done) {
      this.ignoreSubViewOpenFlip = true;
      const { decorationGabarit } = this.$refs;
      const { decoration, gabarit } = this.openDecoration();
      decorationGabarit.$el.classList.remove("menu-open");
      const tl = gsap.timeline({
        onStart: () => {
          this.cursor = this.$route.name;
        },
        onComplete: done,
      });
      tl.delay(0.625).add(this.animateDecoration({ decoration, gabarit }), "a");
    },
    bindScrollTo() {
      const subview = this.$refs[`subview-${this.$route.name}`];
      if (subview && "bindScrollTo" in subview) {
        subview.bindScrollTo();
      }
    },
    bindingSubView() {
      const subview = this.$refs[`subview-${this.$route.name}`];
      if (subview && "bindAnimation" in subview) {
        console.log("bindingSubView:bindAnimation");
        subview.bindAnimation();
      }
    },
    handleSubViewOpen(el, done) {
      if (this.subviewTl) this.subviewTl.kill()
      this.resetView(el);
      if (this.menuOpen) {
        this.cleanUpRouteClasses();
        this.menuOpen = false;
        this.bindScrollTo();
        done();
        this.bindingSubView();
      } else {
        this.subviewTl = gsap.timeline({
          onStart: () => {
            this.bindScrollTo();
          },
          onComplete: () => {
            done();
            this.lateralNav = true;
            this.bindingSubView();
          },
        });
        if (!this.ignoreSubViewOpenFlip) {
          const { decoration, gabarit } = this.openDecoration();
          this.cleanUpRouteClasses();
          this.$refs.decorationGabarit.$el.classList.remove("loaded");
          const duration = subviewDuration;
          this.subviewTl.add(this.animateDecoration({ decoration, gabarit, duration, ease: "sine.out" }), "a");
          this.subviewTl.add(this.openView(el, duration, "sine.out"), "a");
        } else {
          this.resetView(el, -1);
          this.ignoreSubViewOpenFlip = false;
          this.subviewTl.delay(0.625);
          this.subviewTl.add(this.openView(el, defaultDuration * 1.5), "a");
        }
      }
    },
    handleSubViewLeave(el, done) {
      this.lateralNav = false;
      if (this.menuOpen && el.dataset.name !== this.$route.name) {
        done();
      } else {
        const tl = gsap.timeline({
          onComplete: done,
        });
        if (this.menuOpen) {
          tl.add(this.closeView(el));
        } else {
          tl.add(this.closeView(el, subviewDuration, "sine.inOut"));
        }
      }
    },
    handleEnterLateral(el, done) {
      const tl = gsap.timeline({
        onComplete: done,
      });
      gsap.set(el.querySelector(".caption"), {
        x: "-100%",
      });
      gsap.set(el.querySelector(".caption-helper"), {
        x: "100%",
      });
      tl.to(
        el.querySelector(".caption"),
        {
          x: "0%",
          duration: 0.625,
          ease: "expo.inOut",
        },
        "a"
      )
        .to(
          el.querySelector(".caption-helper"),
          {
            x: "0%",
            duration: 0.625,
            ease: "expo.inOut",
          },
          "a"
        )
        .fromTo(
          el.querySelector(".cover"),
          {
            x: "-100%",
          },
          {
            x: "100%",
            duration: 0.625,
            ease: "expo.inOut",
          },
          "a"
        );
    },
    handleLeaveLateral(el, done) {
      const tl = gsap.timeline({
        onComplete: done,
      });
      tl.to(
        el.querySelector(".caption"),
        {
          x: "100%",
          duration: 0.625,
          ease: "expo.inOut",
        },
        "a"
      )
        .to(
          el.querySelector(".caption-helper"),
          {
            x: "-100%",
            duration: 0.625,
            ease: "expo.inOut",
          },
          "a"
        )
        .fromTo(
          el.querySelector(".cover"),
          {
            x: "-100%",
          },
          {
            x: "100.5%",
            duration: 0.625,
            ease: "expo.inOut",
          },
          "a"
        )
        .set(el.querySelector(".cover"), {
          autoAlpha: 0,
        });
    },
    handleBeforeEnterPullComponent(el) {
      gsap.set(el, {
        autoAlpha: 0,
      });
    },
    handleEnterPullComponent(el, done) {
      const tl = new gsap.timeline({
        onComplete: done,
      });
      tl.set(el, {
        autoAlpha: 1,
      });
    },
    handleLeavePullComponent(el, done) {
      const tl = new gsap.timeline({
        onComplete: done,
      });
      tl.set(
        el,
        {
          autoAlpha: 0,
        },
        "a"
      );
    },
    handleResize() {
      this.$store.dispatch("setMobileDevice");
      if (window.innerWidth >= 835) {
        state.animated = "x";
      } else {
        state.animated = "y";
      }
    },
  },
  setup() {
    return {
      state,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";
@import "~swiper/swiper.scss";

#app {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background: $dark-blue;
  align-items: flex-start;
  justify-content: center;
  cursor: none;
  @include respond-to("xs-down") {
    min-height: 100%;
  }
}
@media (max-width: $layout-breakpoint-medium) {
  #app {
    align-items: flex-start;
  }
}
</style>
