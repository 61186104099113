<template>
  <div class="scroll-section">
    <div class="scroll-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollSection"
};
</script>

<style scoped lang="scss">
.scroll-section {
  height: 100vh;
  width: calc(100vw - var(--grid-min-gutter) * 2);
  overflow: hidden;
  position: absolute;
  // top: 0;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @include respond-to("xs-down") {
    width: 100%;
    height: 100%;
  }
}

.scroll-content {
  height: 100%;
  width: 100%;
  // overflow: auto;
  // overflow-x: hidden;
  @include respond-to("xs") {
    overscroll-behavior: none;
  }

  
  &:deep(.view) {
    height: 100%;
    overflow: hidden;
  }
  &:deep(.section-container) {  
    height: 100%;
    overflow: hidden;
    
    @include respond-to("xs") {
      overscroll-behavior-y: none;
    }
    @include respond-to("xs-down") {
      height: auto;
      // overflow: auto;
      // -webkit-overflow-scrolling: touch;
      // height: 100% !important;
    }
    
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(#f1f1f1, 0.25);
    }

    &::-webkit-scrollbar-thumb {
      background: #041d37;
    }
  }
  &:deep(.section-gabarit) {  
    // height: 100%;
    overflow: hidden;
  }
}

</style>
