import {
  HalfFloatType,
  Vector2,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  DoubleSide
} from "three";

import Device from "./Device";
import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js';

import positionFragment from "./shaders/position.frag.glsl";



class GPUCompute {
  WIDTH = 128 * 4
  mouse = new Vector2(10000, 10000);
  constructor({ scene, renderer }) {
    this.scene = scene;
    this.renderer = renderer;
    this.init();

    document.body.addEventListener("pointermove", (e) => {
      this.mouse.set(
        (e.clientX / Device.width), 1.0 - (e.clientY / Device.height)
      );
    });
    // this.setDebug();
  }
  init() {
    const { renderer } = this;
    this.gpuCompute = new GPUComputationRenderer(this.WIDTH, this.WIDTH, renderer);
    if (Device.isSafari) {
      this.gpuCompute.setDataType(HalfFloatType);
    }

    this.heightmap0 = this.gpuCompute.createTexture();

    this.heightmapVariable = this.gpuCompute.addVariable("heightmap", positionFragment, this.heightmap0);

    this.gpuCompute.setVariableDependencies(this.heightmapVariable, [this.heightmapVariable]);

    this.heightmapVariable.material.uniforms["ratio"] = { value: Device.width / Device.height };
    this.heightmapVariable.material.uniforms["mousePos"] = { value: this.mouse };
    this.heightmapVariable.material.uniforms["mouseSize"] = { value: 1 / 7 };
    this.heightmapVariable.material.uniforms["viscosityConstant"] = { value: 0.95 };
    this.heightmapVariable.material.uniforms["heightCompensation"] = { value: 0 };
    const error = this.gpuCompute.init();

    if (error !== null) {
      console.error(error);
    }
  }
  setDebug() {
    const geometry = new PlaneBufferGeometry(1, 1, 1);
    this.debugMaterial = new MeshBasicMaterial({
      color: 0xffffff,
      side: DoubleSide
    });
    this.plane = new Mesh(geometry, this.debugMaterial);
    this.scene.add(this.plane);
  }
  handleRender() {
    const { mouse, gpuCompute, heightmapVariable } = this;
    const uniforms = heightmapVariable.material.uniforms;
    uniforms["mousePos"].value.set(mouse.x, mouse.y);
    gpuCompute.compute();
    this.computedTexture = gpuCompute.getCurrentRenderTarget(
      this.heightmapVariable
    ).texture;
    // this.debugMaterial.map = this.computedTexture;
    // this.debugMaterial.needsUpdate = true;
  }
  handleResize() {
    this.heightmapVariable.material.uniforms["ratio"].value = Device.width / Device.height;
  }
}

export default GPUCompute;