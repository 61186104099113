<template>
  <div class="split-text-cover">
    <slot class="slot" />
  </div>
</template>

<script>
import gsap from "gsap";
import SplitText from "gsap/SplitText";

export default {
  name: "SplitTextCover",
  methods: {
    beforeEnter() {
      gsap.set(this.$el.children, {
        opacity: 0
      });

    },
    handleEnter(i) {
      gsap.set(this.$el.children, {
        opacity: 1
      });
      this.splits = new SplitText(this.$el.children, { type: "lines", linesClass: "word" });
      this.splits.lines.forEach(el => {
        const cover = document.createElement("span");
        cover.classList.add("cover");
        const caption = document.createElement("div");
        caption.classList.add("caption");
        const captionHelper = document.createElement("div");
        captionHelper.classList.add("caption-helper");
        
        captionHelper.textContent = el.textContent;
        el.textContent = "";
        caption.appendChild(captionHelper)
        el.appendChild(caption);
        el.appendChild(cover);
      });
      gsap.set(this.$el.querySelectorAll(".caption"), {
        x: "-100%"
      })
      gsap.set(this.$el.querySelectorAll(".caption-helper"), {
        x: "100%"
      })
      const tl = new gsap.timeline(() => {
        // this.splits.revert();
      });
      const stagger = 0.0628;
      tl
        .delay(i * 0.2)
        .to(this.$el.querySelectorAll(".caption"), {
          x: '0%',
          duration: 1,
          stagger,
          ease: "expo.inOut"
        }, "a")
        .to(this.$el.querySelectorAll(".caption-helper"), {
          x: '0%',
          duration: 1,
          stagger,
          ease: "expo.inOut"
        }, "a")
        .to(this.$el.querySelectorAll(".cover"), {
          x: '100.5%',
          duration: 1,
          stagger,
          ease: "expo.inOut"
        }, "a")
        .set(this.$el.querySelectorAll(".cover"), {
          autoAlpha: 0
        })
    }
  }
};
</script>

<style scoped lang="scss">
.split-text-cover {
  &:deep(.word) {
    overflow: hidden;
    position: relative;
    margin-bottom: -0.4em;
    display: inline-block !important;
    // &:first-child {
    //   margin-top: -0.4em;
    // }
  }
  &:deep(.caption) {
    overflow: hidden;
    display: block;
    will-change: transform;
    padding-bottom: 0.3em;
    line-height: 1;
  }
  &:deep(.caption-helper) {
    display: block;
    will-change: transform;
  }
}

</style>
