<template>
  <div
    :class="[`pull-component ${props.mode} index-${idx}`]"
    :style="{
      '--background-color': `var(${route.bgColor})`,
    }"
  >
    <div class="static" />
    <div
      class="movable"
      :style="{
        transform: `translateX(${progress}%)`,
      }"
    >
      <div
        class="lateral-nav-link titre-h3"
        :style="{
          color: `var(${route.color})`,
        }"
      >
        {{ route.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { state } from "./state.js";
const props = defineProps({
  mode: {
    type: String,
    default: "next",
  },
  route: Object,
});
const progress = computed(() => {
  if (props.mode === "previous") {
    return -state.progress - 100;
  } else {
    return 100 - state.progress;
  }
});
const idx = computed(() => {
  return state.getViewIdx(props.route.name);
});
</script>

<style scoped lang="scss">
.pull-component {
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  right: 0;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  height: auto;
  width: 330px;
  pointer-events: none;
  opacity: 0.5 !important;

  &.next {
    &.index-1 {
      right: var(--grid-min-double-gutter);
      .static {
        transform: translateX(200%);
        background-color: var(--light-blue);
      }
      .movable {
        right: 0;
        box-sizing: content-box;
        // margin-left: var(--grid-negative-min-gutter);
        // padding-left: var(--grid-min-gutter);
        margin-right: var(--grid-negative-min-gutter);
        padding-right: var(--grid-min-double-gutter);

        &:before {
          content: "";
          display: block;
          width: var(--grid-min-gutter);
          background-color: var(--background-color);
          height: 100%;
          position: absolute;
          right: 100%;
          top: 0;

          z-index: 1;
        }
      }
    }
    &.index-2 {
      .lateral-nav-link {
        padding-left: var(--grid-min-gutter);
      }
    }
  }

  &.previous {
    right: initial;
    left: 0;

    &.index-1 {
      left: var(--grid-min-double-gutter);
      .static {
        transform: translateX(-200%);
        background-color: var(--brand-blue);
      }
      .movable {
        left: 0;
        box-sizing: content-box;
        margin-left: var(--grid-negative-min-gutter);
        &:after {
          content: "";
          display: block;
          background-color: var(--light-blue);
          // background-color: pink;
          width: var(--grid-min-gutter);
          height: 100%;
          position: absolute;
          top: 0;
          left: 100%;
        }
      }
    }

    .static {
      right: initial;
      left: 0;
    }
  }
}

.static {
  width: var(--grid-min-gutter);
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
  .index-0 &,
  .index-2 & {
    display: none;
  }
}
.movable {
  position: relative;
  height: 100%;
  width: auto;
  min-width: 100%;
  background-color: var(--background-color);
  // background-color: red;
  will-change: transform;

  .next & {
    right: var(--grid-min-gutter);
    &:before {
      content: "";
      display: block;
      width: var(--grid-min-gutter);
      background-color: var(--green);
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      z-index: 1;
      margin-right: -1px;
      padding-right: -1px;
    }
  }
  .previous & {
    left: var(--grid-min-gutter);
    &:after {
      content: "";
      display: block;
      background-color: var(--green);
      // background-color: pink;
      width: var(--grid-min-gutter);
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
    }
  }
}

.lateral-nav-link {
  padding: 18.51vh 0 var(--grid-min-gutter);
}
</style>
