<template>
  <ScrollSection ref="scrollSection">
    <div class="view atelier" ref="view">
      <div class="section-container" ref="sectionContainer">
        <div class="section-sizer">
          <div class="section-gabarit" ref="sectionGabarit">
            <div class="container hero fullscreen">
              <Experience />
              <Grid />
              <Logo />
              <SplitTextCover
                class="behavior"
                ref="behavior22"
                data-behavior="22"
                :style="{
                  '--grid-column': `2 / span 6`,
                  'margin-right': '13%',
                }"
              >
                <h1 class="titre-h1">
                  {{ $t("landingTitle") }}
                </h1>
              </SplitTextCover>
            </div>
            <div class="image-parallax" ref="imgParallax">
              <div class="image-helper">
                <img class="scaled-image" src="@/assets/photos/meetup-1.png" alt="image event" />
              </div>
            </div>
            <div class="container dark-layout" ref="about" id="about" :style="{ '--dark-blue': '#012b64' }">
              <!-- <div
                class="texte-p3"
                :style="{
                  '--grid-column': `4 / span 2`,
                  'margin-bottom': '1em',
                  color: 'var(--light-blue)',
                }"
              >
                À propos de nous
              </div> -->
              <div
                class="titre-h1 corps-title"
                :style="{
                  '--grid-column': `2 / span 6`,
                  color: 'var(--green)',
                }"
              >
                <TextCover class="behavior" ref="behavior1200" data-behavior="1200">
                  <h2>À propos de nous</h2>
                </TextCover>
              </div>
              <div
                class="unit"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <!-- <div class="illustration"></div> -->
                <!-- <TextCover class="behavior" ref="behaviortext1" data-behavior="text1">
                  <h3 class="titre-h2" style="color: var(--green)">Lorem ipsum</h3>
                </TextCover> -->
                <SplitLines
                  class="behavior"
                  ref="behavior212459"
                  data-behavior="212459"
                >
                <p class="texte-p1">
                  Passionnés par le développement web et véritables artisans du code,<br/>nous avons créé l’Atelier en 2016
                  dans le but d’accompagner nos clients<br/>sur leurs problématiques en leur apportant notre expertise et
                  nos valeurs.
                </p>
                <p class="texte-p1">
                  Concrètement, nous construisons et développons vos applications web<br/>au sein de vos équipes et plus
                  encore…
                </p>
                </SplitLines>
              </div>
            </div>
            <!-- <Timeline class="dark-layout" id="history"> </Timeline> -->
            <div class="container" id="philosophy">
              <TypingText
                class="behavior"
                ref="behavior21245"
                data-behavior="21245"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <h2 class="medium-highlight-sentence">
                  Au-delà de son expertise technique,<br />l’identité de L’Atelier repose sur trois philosophies
                </h2>
              </TypingText>

              <div class="section section-philosophy">
                <div class="philosophy agility unit">
                  <div class="image-main-container">
                    <!-- <img src="@/assets/png/agility.png" /> -->
                    <Agility />
                  </div>

                  <Alpha class="behavior" ref="behavior1" data-behavior="1">
                    <h3 class="titre-h2">Agilité</h3>
                  </Alpha>

                  <Alpha class="behavior" ref="behavior221" data-behavior="221">
                    <p class="texte-p1">
                      Préconiser une approche d’amélioration continue, encourager une réponse rapide et souple au
                      changement.
                    </p>
                  </Alpha>
                </div>
                <div class="philosophy craftsmanship unit">
                  <div class="image-main-container">
                    <!-- <img src="@/assets/png/craftsmanship.png" /> -->
                    <Craftsmanship />
                  </div>

                  <Alpha class="behavior" ref="behavior12" data-behavior="12">
                    <h3 class="titre-h2">Craftsmanship</h3>
                  </Alpha>

                  <Alpha class="behavior" ref="behavior222" data-behavior="222">
                    <p class="texte-p1">
                      Créer le bon produit en mettant au cœur de nos réalisations la fiabilité, la qualité et la
                      maintenabilité du code.
                    </p>
                  </Alpha>
                </div>
                <div class="philosophy devOps unit">
                  <div class="image-main-container">
                    <!-- <img src="@/assets/png/devOps.png" /> -->
                    <DevOps />
                  </div>

                  <Alpha class="behavior" ref="behavior13" data-behavior="13">
                    <h3 class="titre-h2">DevOps</h3>
                  </Alpha>

                  <Alpha class="behavior" ref="behavior223" data-behavior="223">
                    <p class="texte-p1">
                      Adopter les pratiques et les outils qui améliorent la capacité à livrer des versions
                      opérationnelles à un rythme élevé.
                    </p>
                  </Alpha>
                </div>

              </div>
              <RoundCta class="dark-layout mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <RoundCta class="dark-layout" />
  </ScrollSection>
</template>

<script>
import { ref, provide, onMounted } from "vue";
import gsap from "gsap";

import ViewBehavior from "@/mixins/ViewBehavior";

import ScrollSection from "@/components/ScrollSection";
import Logo from "@/components/Logo";
// import Timeline from "@/components/Timeline.vue";
import Grid from "@/components/Grid.vue";
import Experience from "@/components/Experience";
import Agility from "@/components/latelier/Agility";
import Craftsmanship from "@/components/latelier/Craftsmanship";
import DevOps from "@/components/latelier/DevOps";
import RoundCta from "@/components/RoundCta";

import Device from "@/experience/Device";

export default {
  name: "LAtelier",
  mixins: [ViewBehavior],
  components: {
    ScrollSection,
    Logo,
    // Timeline,
    Grid,
    Experience,
    Agility,
    Craftsmanship,
    DevOps,
    RoundCta,
  },
  setup() {
    const sectionContainer = ref(null);
    const imgParallax = ref(null);
    const about = ref(null);
    provide("scroller", sectionContainer);

    if (Device.width >= 835) {
      onMounted(() => {
        gsap.fromTo(
          imgParallax.value.querySelector(".image-helper"),
          {
            y: "-62.5%",
          },
          {
            y: "62.5%",
            ease: "none",
            scrollTrigger: {
              scroller: sectionContainer.value,
              trigger: imgParallax.value,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          imgParallax.value.querySelector(".image-helper"),
          {
            opacity: 1,
          },
          {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              scroller: sectionContainer.value,
              trigger: about.value,
              start: "top bottom",
              end: "bottom bottom",
              scrub: true,
              // markers: true
            },
          }
        );
      });
    }

    return {
      sectionContainer,
      imgParallax,
      about,
    };
  },
};
</script>

<style scoped lang="scss">
.atelier {
  --default-text-color: var(--white);

  h1,
  .medium-highlight-sentence {
    color: var(--green);
  }
}
.philosopher {
  grid-template-columns: repeat(12, 1fr);
}
.philosophy {
  &:nth-child(1) {
    grid-column: 2 / span 3;
  }
  &:nth-child(2) {
    grid-column: 6 / span 3;
  }
  &:nth-child(3) {
    grid-column: 10 / span 3;
  }
  @include respond-to("xs-down") {
    grid-column: var(--grid-column) !important;
  }
  img {
    position: absolute;
    width: auto;
    height: 100%;
    z-index: -1;
  }
  .texte-p1 {
    line-height: 1.3;
  }
}
.image-main-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  height: 15.3vw;
  width: 100%;
  margin-bottom: 5.375vw;
  @include respond-to("xs-down") {
    height: auto;
    margin-top: 60px;
    margin-bottom: 0px;
    justify-content: center;
  }

  .pixel-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.corps-title {
  margin-top: -0.1em;
  margin-bottom: 0.75em;

  @include respond-to("xs-down") {
    margin-top: 0.75em;
  }
}
.section-philosophy {
  @include respond-to("xs-down") {
    padding: 0;
    max-width: 342px;
    margin-left: auto;
    margin-right: auto;
  }
}
.image-parallax {
  height: 100vh;
  position: relative;
  --dark-blue: #012b64;
  background-color: var(--dark-blue);

  @include respond-to("xs-down") {
    overflow: hidden;
  }
  .image-helper {
    position: absolute;
    left: 0;
    top: -12.5%;
    width: 100%;
    height: 130%;
    @include respond-to("xs-down") {
      top: 0%;
      height: 100%;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.unit {
  z-index: 1;
}
.hero {
  z-index: 2;
}
</style>
