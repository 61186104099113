<template>
  <div class="decoration-gabarit" data-highlight="default">
    <div class="decoration-helper">
      <Component
        :is="'div'"
        :key="name"
        v-for="({ name, bgColor }, idx) in slots"
        :class="[
          `decoration decoration-${idx} sub-view-${name}`,
          {
            active: $route.name === name,
            unactive: $route.name !== name,
          },
        ]"
        :style="{
          '--background-color': `var(${bgColor})`,
        }"
        @mouseenter="handleMouseenter(name)"
        @mouseleave="handleMouseleave(name)"
      >
        <slot :name="name"></slot>
      </Component>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionGabarit",
  emits: ["updateHighlight"],
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
    slots: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleMouseenter(type) {
      if (!this.locked) {
        this.$emit("updateHighlight", type);
      }
    },
    handleMouseleave(type) {
      if (this.$route.name !== type) {
        this.$emit("updateHighlight", "default");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.decoration-gabarit {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  .decoration-helper {
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: all;
    @include respond-to("xs-down") {
      flex-direction: column;
    }
  }
  &.menu-open {
    @include respond-to("xs-down") {
      align-items: flex-start;
      padding-top: 250px;
    }
    .decoration {
      flex: 1;
    }
    .decoration-helper {
      width: 80%;
      height: 26.25vw;
      @include respond-to("xs-down") {
        // flex-direction: column;
        width: 100%;
        height: 144px;
      }
    }
    .decoration-0 {
      @include respond-to("xs") {
        max-width: initial !important;
      }
      @include respond-to("xs-down") {
        max-height: initial !important;
      }
    }
    .decoration-1 {
      @include respond-to("xs") {
        max-width: initial !important;
        margin-left: 0px !important;
      }
      @include respond-to("xs-down") {
        max-height: initial !important;
      }
    }
    .decoration-2 {
      @include respond-to("xs") {
        max-width: initial !important;
        margin-left: 0px !important;
      }
      @include respond-to("xs-down") {
        max-height: initial !important;
      }
    }
  }
  &.loading {
    .decoration {
      opacity: 1;
    }
    .decoration-helper {
      width: 72px;
      height: 24px;
      @include respond-to("xs-down") {
        width: 24px;
        height: 72px;
      }
    }
  }
  &.loading-0 {
    @include respond-to("xs") {
      .decoration-0 {
        width: 40px;
      }
      .decoration-1 {
        width: 16px;
      }
      .decoration-2 {
        width: 16px;
      }
    }
    @include respond-to("xs-down") {
      .decoration-0 {
        height: 40px;
      }
      .decoration-1 {
        height: 16px;
      }
      .decoration-2 {
        height: 16px;
      }
    }
  }
  &.loading-1 {
    @include respond-to("xs") {
      .decoration-0 {
        width: 16px;
      }
      .decoration-1 {
        width: 40px;
      }
      .decoration-2 {
        width: 16px;
      }
    }
    @include respond-to("xs-down") {
      .decoration-0 {
        height: 16px;
      }
      .decoration-1 {
        height: 40px;
      }
      .decoration-2 {
        height: 16px;
      }
    }
  }
  &.loading-2 {
    @include respond-to("xs") {
      .decoration-0 {
        width: 16px;
      }
      .decoration-1 {
        width: 16px;
      }
      .decoration-2 {
        width: 40px;
      }
    }
    @include respond-to("xs-down") {
      .decoration-0 {
        height: 16px;
      }
      .decoration-1 {
        height: 16px;
      }
      .decoration-2 {
        height: 40px;
      }
    }
  }

  &.loaded {
    .decoration {
      opacity: 1;
    }
    .decoration-helper {
      @include respond-to("xs") {
        width: 72px;
        height: 100%;
      }
      @include respond-to("xs-down") {
        height: 72px;
        width: 100%;
      }
    }
  }
}
.decoration {
  // width: auto;

  position: relative;
  // top: 0;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--background-color);
  opacity: 1;

  &:before {
    content: "";
    width: var(--grid-min-gutter);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    background-color: var(--background-color);
  }
  &:after {
    content: "";
    width: var(--grid-min-gutter);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    background-color: var(--background-color);
  }

  &.sub-view-LAtelier {
    &:before {
      background-color: transparent;
    }
    &:after {
      background-color: transparent;
    }
  }

  &.active {
    z-index: 0;
  }
  &.unactive {
    z-index: 1;
  }
}
.section-LAtelier {
  .decoration-1 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }
  .decoration-2 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }

  &[data-highlight="Offer"] {
    .decoration-1 {
      max-width: 330px;
    }
  }
  &[data-highlight="Consultants"] {
    .decoration-2 {
      max-width: 330px;
    }
  }
}
.section-Offer {
  .decoration-0 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }
  .decoration-2 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }

  &[data-highlight="LAtelier"] {
    .decoration-0 {
      max-width: 330px;
    }
  }
  &[data-highlight="Consultants"] {
    .decoration-2 {
      max-width: 330px;
    }
  }
}
.section-Consultants {
  .decoration-0 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }
  .decoration-1 {
    max-width: var(--grid-min-gutter);
    @include respond-to("xs-down") {
      max-width: initial;
      max-height: var(--grid-min-gutter);
    }
  }

  &[data-highlight="LAtelier"] {
    .decoration-0 {
      max-width: 330px;
    }
  }
  &[data-highlight="Offer"] {
    .decoration-1 {
      max-width: 330px;
    }
  }
}
</style>
