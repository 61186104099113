import { reactive, computed } from "vue";
import router from "@/router";
const slots = [
  {
    name: 'LAtelier',
    bgColor: '--brand-blue',
    color: '--green',
    gutterColor: 'transparent',
    label: "L'Atelier"
  },
  {
    name: 'Offer',
    bgColor: '--green',
    color: '--dark-blue',
    gutterColor: '--green',
    label: "Notre offre"
  },
  {
    name: 'Consultants',
    bgColor: '--light-blue',
    color: '--white',
    gutterColor: '--light-blue',
    label: "Nos consultants"
  }
];

const getViewIdx = (route) => {
  return slots.findIndex(item => item.name === route);
};

const nextabilty = (route) => {
  const idx = getViewIdx(route);
  return idx < slots.length - 1;
};

const previousabilty = (route) => {
  const idx = getViewIdx(route);
  return idx > 0;
};

export const state = reactive({
  slots,
  canNext: false,
  canPrevious: false,
  nextRoute: computed(() => {
    const route = router.currentRoute.value.name;
    if (nextabilty(route)) {
      const idx = getViewIdx(route);
      const nextRoute = slots[idx + 1];
      return nextRoute;
    } else {
      return false;
    }
  }),
  previousRoute: computed(() => {
    const route = router.currentRoute.value.name;
    if (previousabilty(route)) {
      const idx = getViewIdx(route);
      const previousRoute = slots[idx - 1];
      return previousRoute;
    } else {
      return false;
    }
  }),
  direction: 1,
  progress: 0,
  animated: "x",
  updateProgress(value) {
    this.progress = value;
  },
  getViewIdx,
  nextabilty,
  previousabilty,
  updateDirection(value = 1) {
    this.direction = value;
  },
  previousView(route) {
    this.canPrevious = false;
    const idx = getViewIdx(route);
    const { name } = slots[idx - 1];
    router.push({
      name
    });
  },
  nextView(route) {
    this.canNext = false;
    const idx = getViewIdx(route);
    const { name } = slots[idx + 1];
    router.push({
      name
    });
  }
});