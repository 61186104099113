<template>
  <a class="round-cta" href="https://www.welcometothejungle.com/fr/companies/l-atelier" target="_blank">
    <span class="circle-text">Nous rejoindre !</span>
  </a>
</template>

<script>
export default {
  name: "RoundCta",
};
</script>

<style scoped lang="scss">
.round-cta {
  position: absolute;
  z-index: 2;
  bottom: 3.125vw;
  right: 3.75vw;
  background-color: var(--brand-blue);
  width: auto;
  height: auto;
  padding: 0.5em 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($green, 0.1);
  cursor: none;
  &.mobile {
    display: none;
  }
  @include respond-to("xs-down") {
    display: none;
    &.mobile {
      display: flex;
      position: relative;
      transform: none;
      right: initial;
      bottom: initial;
      margin: 30px auto;
    }
  }
  &.dark-layout {
    background-color: var(--dark-blue);
  }
  &:hover {
    background-color: var(--light-blue);
  }
}
.circle-text {
  color: var(--green);
  white-space: nowrap;
  font-size: 0.8rem;
}
</style>
