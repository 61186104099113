import gsap from "gsap";
import { state } from "@/components/PullComponent/state";

class LoadingAnimation {
    constructor({
        decorationGabarit,
        openDecoration,
        animateDecoration
    }) {
        this.openDecoration = openDecoration;
        this.animateDecoration = animateDecoration;

        this.idx = 0;
        this.activities = [
            {
                ease: "linear",
                duration: 0.3125,
                toDo: () => {
                    decorationGabarit.$el.classList.add("loading-0");
                },
            },
            {
                ease: "linear",
                duration: 0.3125,
                toDo: () => {
                    decorationGabarit.$el.classList.remove("loading-0");
                    decorationGabarit.$el.classList.add("loading-1");
                },
            },
            {
                ease: "linear",
                duration: 0.3125,
                toDo: () => {
                    decorationGabarit.$el.classList.remove("loading-1");
                    decorationGabarit.$el.classList.add("loading-2");
                },
            },
            {
                ease: "sine.out",
                duration: 0.3125,
                toDo: () => {
                    decorationGabarit.$el.classList.remove("loading-2");
                },
            },
            {
                ease: "expo.inOut",
                duration: 0.625,
                toDo: () => {
                    decorationGabarit.$el.classList.remove("loading");
                    decorationGabarit.$el.classList.add("loaded");
                },
            },
        ];

        const tl = gsap.timeline();
        tl.set(decorationGabarit.$el.querySelectorAll(".decoration"), {
            alpha: 0,
        }).to(
            decorationGabarit.$el.querySelectorAll(".decoration"),
            {
                alpha: 1,
                duration: 0.3,
                stagger: 0.1,
            },
            "pre"
        );

        this.loadingNext(tl);

        return tl;

    }
    loadingNext(tl) {
        const { activities, idx } = this;
        const activity = activities[idx];
        const { decoration, gabarit } = this.openDecoration();

        const { toDo, ease, duration } = activity;
        toDo();

        const loading = this.animateDecoration({ decoration, gabarit, ease, duration });

        tl.add(loading, `a${idx}`);
        if (idx < activities.length - 1) {
            loading.eventCallback("onComplete", () => {
                this.idx += 1;
                this.loadingNext(tl);
            });
        }
    }
}

const defaultDuration = 0.625;
function resetView(el, direction = 1) {
    const { animated } = state;
    gsap.set(
        el.querySelector(".view"),
        {
            [animated]: `${direction * state.direction * 100}%`,
            duration: 0.625,
            ease: "expo.out",
        },
        "a"
    );
    gsap.set(
        el.querySelector(".section-container"),
        {
            [animated]: `${direction * state.direction * -62.5}%`,
            duration: 0.625,
            ease: "expo.out",
        },
        "a"
    );
}
function openView(el, duration = defaultDuration, ease = "expo.out") {
    const { animated } = state;
    gsap.killTweensOf(el.querySelector(".view"));
    gsap.killTweensOf(el.querySelector(".section-container"));

    const tl = new gsap.timeline();
    tl.to(
        el.querySelector(".view"),
        {
            [animated]: "0%",
            duration,
            ease,
        },
        "a"
    ).to(
        el.querySelector(".section-container"),
        {
            [animated]: "0%",
            duration,
            ease,
        },
        "a"
    );
    return tl;
}
function closeView(el, duration = defaultDuration, ease = "expo.out") {
    const { animated } = state;
    gsap.killTweensOf(el.querySelector(".view"));
    gsap.killTweensOf(el.querySelector(".section-container"));
    const tl = new gsap.timeline();
    if (this.menuOpen) {
        duration *= 2;
    }
    tl.to(
        el.querySelector(".view"),
        {
            [animated]: `${state.direction * (this.menuOpen ? 100 : 50)}%`,
            duration,
            ease
        },
        "a"
    ).to(
        el.querySelector(".section-container"),
        {
            [animated]: `${state.direction * (this.menuOpen ? -100 : -75)}%`,
            duration,
            ease
        },
        "a"
    );

    return tl;
}
export {
    LoadingAnimation,
    resetView,
    openView,
    closeView
}