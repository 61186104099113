<template>
  <div class="events">
    <swiper
      slides-per-view="auto"
      :snap-grid="true"
      @swiper="onSwiper"
      @snapGridLengthChange="snapGridLengthChange"
      @reachEnd="reachEnd"
      @slideChange="slideChange"
      :speed="628"
      :modules="modules"
      direction="horizontal"
      :mousewheel="{ forceToAxis: true }"
      class="main-swiper"
    >
      <Navigation @previous="handlePrevious" @next="handleNext" :previousable="previousable" :nextable="nextable" />
      <swiper-slide
        v-for="(event, index) in events"
        :key="event"
        :class="[event, { active: event === 'meetups' }]"
        @click="updateSwiper(index)"
      >
        <h3 class="titre-h2">{{ $t(`events.${event}`) }}</h3>
      </swiper-slide>
    </swiper>
    <Transition @enter="handleEnter" @leave="handleLeave" :css="false" mode="out-in">
      <Component :is="currentEvent" :key="currentEvent" />
    </Transition>
  </div>
</template>

<script>
import gsap from "gsap";
import { ref, computed } from "vue";

import Meetups from "./eventsContent/ContentMeetups.vue";
// import TeamBuilding from "./eventsContent/ContentTeamBuilding.vue";
import Training from "./eventsContent/ContentTraining.vue";
import Techdays from "./eventsContent/ContentTechdays.vue";
import Mentoring from "./eventsContent/ContentMentoring.vue";
import Navigation from "@/components/Navigation.vue";
import { useNavigation } from "@/composable/Navigation.js";

import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "Events",
  components: {
    Meetups,
    // TeamBuilding,
    Training,
    Techdays,
    Mentoring,
    Swiper,
    SwiperSlide,
    Navigation,
  },
  setup() {
    const { idx, swiper, previousable, nextable, handlePrevious, handleNext, onSwiper, slideChange } = useNavigation();

    const events = ref(["techdays", "meetups", "training", "mentoring"]);
    const currentEvent = computed(() => {
      return events.value[idx.value];
    });

    const snapGridLengthChange = (instance) => {
      if (instance.snapGrid.length != instance.slidesGrid.length) {
        instance.snapGrid = instance.slidesGrid.slice(0);
      }
    };

    const reachEnd = (instance) => {
      instance.snapGrid = [...instance.slidesGrid];
    };

    const updateSwiper = (index) => {
      swiper.value.slideTo(index);
    };

    const handleEnter = (el, done) => {
      const images = el.querySelectorAll("img");
      const covers = el.querySelectorAll(".cover");
      const textes = el.querySelectorAll(".main-paragraph");
      gsap.set(covers, {
        x: "-100.5%",
      });
      gsap.set(images, {
        x: "0%",
        alpha: 0,
      });
      const tl = new gsap.timeline({
        onComplete: done,
      });
      tl.from(
        textes,
        {
          alpha: 0,
          duration: 1,
          stagger: 0.075,
          ease: "linear",
        },
        "a"
      )
        .to(
          covers,
          {
            x: "100.5%",
            duration: 1,
            stagger: 0.075,
            ease: "quint.inOut",
          },
          "a"
        )
        .set(
          images,
          {
            alpha: 1,
            stagger: 0.075,
          },
          "a+=0.5"
        )
        .to(
          images,
          {
            x: "0%",
            duration: 0.5,
            stagger: 0.075,
            ease: "quint.out",
          },
          "a+=0.5"
        )
        .set(covers, {
          autoAlpha: 0,
          x: "-100.5%",
        });
    };
    const handleLeave = (el, done) => {
      const images = el.querySelectorAll("img");
      const covers = el.querySelectorAll(".cover");
      const textes = el.querySelectorAll(".main-paragraph");
      gsap.killTweensOf(images);
      gsap.killTweensOf(covers);
      gsap.killTweensOf(textes);
      const tl = new gsap.timeline({
        onComplete: done,
      });
      tl.set(
        covers,
        {
          autoAlpha: 1,
          x: "-100.5%",
        },
        "a"
      )
        .to(
          textes,
          {
            alpha: 0,
            duration: 0.625,
            ease: "linear",
          },
          "a"
        )
        .to(
          covers,
          {
            x: "100.5%",
            duration: 0.625,
            stagger: 0.075,
            ease: "quint.inOut",
          },
          "a"
        )
        .to(
          images,
          {
            x: "0%",
            duration: 0.3125,
            stagger: 0.075,
            ease: "quint.in",
          },
          "a"
        )
        .set(
          images,
          {
            alpha: 0,
            stagger: 0.075,
          },
          "a+=0.3125"
        );
    };
    return {
      events,
      currentEvent,
      snapGridLengthChange,
      reachEnd,
      slideChange,
      updateSwiper,
      handleEnter,
      handleLeave,

      onSwiper,
      handlePrevious,
      handleNext,
      previousable,
      nextable,

      modules: [Mousewheel],
    };
  },
};
</script>

<style scoped lang="scss">
.events {
  margin-bottom: 12vw;
  @include respond-to("xs-down") {
    margin-bottom: 72px;
  }
}
.swiper {
  overflow: visible;
  margin-top: 6.25vw;
  @include respond-to("xs-down") {
    margin-top: 96px;
    &:nth-child(2) {
      margin-top: 24px;
    }
  }
}
.swiper-slide {
  width: auto;
  margin-right: 5.1vw;
  opacity: 0.3;
  transition: opacity 0.3s linear;
  // cursor: pointer
  cursor: none;
  &:hover {
    opacity: 0.5;
  }
  &.swiper-slide-active {
    opacity: 1;
  }
}
.titre-h2 {
  color: var(--light-blue);
}

.sub-swiper {
  margin-top: 3.25vw;
  &:deep(.swiper-slide) {
    width: 1% !important;
    display: table;
    margin-right: 1.8vw;
    @include respond-to("xs-down") {
      margin-right: 14px;
    }
    img {
      height: 16.875vw;
      width: auto;
      display: block;

      @include respond-to("xs-down") {
        height: 300px;
      }
    }
    .cell {
      height: auto;
      overflow: hidden;
    }
  }
  &:deep(.main-paragraph) {
    display: -webkit-box;
    // max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 6.2em;
    margin-top: 1.5em;
    @include respond-to("xs") {
      max-width: 32vw;
    }
  }
}
</style>
