import AltareaLogo from "@/assets/clients/logo-altarea.png";
import ArrowLogo from "@/assets/clients/logo-arrow.png";
import AuthotLogo from "@/assets/clients/logo-authot.png";
import CarrefourLogo from "@/assets/clients/logo-carrefour.png";
import CentraleLogo from "@/assets/clients/logo-centrale.png";
import DartyLogo from "@/assets/clients/logo-darty.png";
import DocapostLogo from "@/assets/clients/logo-docapost.png";
import EulerHermesLogo from "@/assets/clients/logo-eulerhermes.png";
import EurosportLogo from "@/assets/clients/logo-eurosport.png";
import FnacLogo from "@/assets/clients/logo-fnac.png";
import G7Logo from "@/assets/clients/logo-g7.png";
import InfogreffeLogo from "@/assets/clients/logo-infogreffe.png";
import IPSENLogo from "@/assets/clients/logo-IPSEN.png";
import JLLLogo from "@/assets/clients/logo-JLL.png";
import JobTeaserLogo from "@/assets/clients/logo-jobteaser.png";
import KeringLogo from "@/assets/clients/logo-kering.png";
import LacosteLogo from "@/assets/clients/logo-lacoste.png";
import MappyLogo from "@/assets/clients/logo-mappy.png";
import MTELogo from "@/assets/clients/logo-ministere-transition-ecologique.png";
import SeLogerLogo from "@/assets/clients/logo-seloger.png";
import SocieteGeneraleLogo from "@/assets/clients/logo-societegenerale.png";
import TeleramaLogo from "@/assets/clients/logo-telerama.png";
import TotalEnergiesLogo from "@/assets/clients/logo-totalenergies.png";
import TUILogo from "@/assets/clients/logo-TUI.png";
import UPECLogo from "@/assets/clients/logo-upec.png";

export default [

  {
    name: "Altarea",
    url: "https://www.altarea.com/",
    logo: AltareaLogo,
  },
  {
    name: "Arrow",
    url: "https://www.arrow.com/globalecs/na/platforms/arrowsphere",
    logo: ArrowLogo,
  },
  {
    name: "Authot",
    url: "https://www.authot.com/fr/",
    logo: AuthotLogo,
  },
  {
    name: "Carrefour",
    url: "https://www.carrefour.com/fr",
    logo: CarrefourLogo,
  },
  {
    name: "La Centrale",
    url: "https://www.lacentrale.fr/",
    logo: CentraleLogo,
  },
  {
    name: "Darty",
    url: "https://www.darty.com/",
    logo: DartyLogo,
  },
  {
    name: "Docapost",
    url: "https://www.docaposte.com/",
    logo: DocapostLogo,
  },
  {
    name: "Euler Hermes",
    url: "https://www.eulerhermes.fr/",
    logo: EulerHermesLogo,
  },
  {
    name: "Eurosport",
    url: "https://www.eurosport.fr/",
    logo: EurosportLogo,
  },
  {
    name: "Fnac",
    url: "https://www.fnac.com/",
    logo: FnacLogo,
  },
  {
    name: "G7",
    url: "https://www.g7.fr/",
    logo: G7Logo,
  },
  {
    name: "Infogreffe",
    url: "https://infogreffe.fr",
    logo: InfogreffeLogo,
  },
  {
    name: "IPSEN",
    url: "https://www.ipsen.com/fr/",
    logo: IPSENLogo,
  },
  {
    name: "JLL",
    url: "https://www.jll.fr/",
    logo: JLLLogo,
  },
  {
    name: "JobTeaser",
    url: "https://www.jobteaser.com/fr",
    logo: JobTeaserLogo,
  },
  {
    name: "Kering",
    url: "https://www.kering.com/fr/",
    logo: KeringLogo,
  },
  {
    name: "Lacoste",
    url: "https://www.lacoste.com/fr/",
    logo: LacosteLogo,
  },
  {
    name: "Mappy",
    url: "https://fr.mappy.com/",
    logo: MappyLogo,
  },
  {
    name: "Ministère de la Transition écologique",
    url: "https://www.ecologie.gouv.fr/",
    logo: MTELogo,
  },
  {
    name: "Se Loger",
    url: "https://www.seloger.com/",
    logo: SeLogerLogo,
  },
  {
    name: "Societe Generale",
    url: "https://www.societegenerale.com/fr",
    logo: SocieteGeneraleLogo,
  },
  {
    name: "Telerama",
    url: "https://www.telerama.fr/",
    logo: TeleramaLogo,
  },
  {
    name: "TotalEnergies",
    url: "https://www.totalenergies.fr/",
    logo: TotalEnergiesLogo,
  },
  {
    name: "TUI",
    url: "https://www.tui.fr/",
    logo: TUILogo,
  },
  {
    name: "UPEC",
    url: "https://www.u-pec.fr/",
    logo: UPECLogo,
  },
];