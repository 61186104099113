<template>
  <div class="contact texte-p3" :class="{ 'contact-footer': $route.name === 'Consultants' && !menuOpen }">
    <Logo class="logo" />
    <a
      class="address paragraph"
      href="https://www.google.fr/maps/place/L'Atelier/@48.8605721,2.3403635,17z/data=!4m5!3m4!1s0x47e66e23f94fffff:0x250f3d974bcd47ce!8m2!3d48.8605766!4d2.3425309"
      target="_blank"
      >46 rue de l’Arbre Sec <br />75001 Paris, <br />France
    </a>
    <div>
      <a class="email link underline" href="mailto:contact@latelier.co">hello@latelier.co</a>
    </div>
    <div class="social-media">
      <a href="https://www.welcometothejungle.com/fr/companies/l-atelier" target="_blank">
        <Welcome />
      </a>
      <a href="https://fr.linkedin.com/company/latelier.co" target="_blank">
        <Linkedin />
      </a>
    </div>
  </div>
</template>

<script>
import Welcome from "@/assets/svg/welcome-icon.svg";
import Linkedin from "@/assets/svg/linkedin-icon.svg";
import Logo from "@/assets/svg/logo-blue-bg-tranparent.svg";

export default {
  name: "Contact",
  props:{
    menuOpen:{
      type:Boolean
    }
  },
  components: {
    Linkedin,
    Welcome,
    Logo,
  },
};
</script>

<style scoped lang="scss">
.contact {
  color: $white;
  // position: absolute;
  // right: 10%;
  // bottom: 2vh;

  .social-media {
    display: flex;
  }

  .paragraph {
    color: $white;
  }

  a {
    // display: block;
    margin-top: 0.6em;
    margin-bottom: 0.6em;

    &.email {
      color: $white;
      // &:after {
      //   background: $white;
      // }
    }

    &:hover {
      color: $green;

      // &:after {
      //   background: $green;
      // }
    }

    svg {
      width: 1.25em;
      height: 1.75em;
      margin-right: 1em;
    }
  }
}

.logo {
  display: none;
}
</style>
