import {
  PerspectiveCamera,
  Vector2,
  Vector3
} from "three";

import Device from "./Device";

const cameraDummy = new PerspectiveCamera(54.43, Device.width / Device.height, 0.1, 550);

class Cameras {
  truck = new Vector3();
  pedestal = new Vector3();
  dolly = new Vector3();
  dummyDolly = new Vector3(0, 0, 1);
  
  currentPosition = new Vector3(0, 0, 0);
  dummyPosition = new Vector3(0, 0, 9);
  lerpedMouse = new Vector2(0, 0);
  instances = {
    main: cameraDummy,
  }

  constructor() {
    const { dummyPosition } = this;

    this.instances.main.position.copy(dummyPosition);
  }
  handleMousemove(e) {
    const { mouse, width, height } = Device;
    mouse.x = (e.clientX / width);
    mouse.y = (e.clientY / height);
  }
  render() {
  }
  dispose() {
  }
  handleResize() {
    Object.keys(this.instances).forEach((_) => {
      this.instances[_].aspect = Device.width / Device.height;
      this.instances[_].setFocalLength(54.43);
      this.instances[_].updateProjectionMatrix();
    });
  }
}

export default Cameras;