<template>
  <swiper
    class="swiper-container sub-swiper"
    slides-per-view="auto"
    :snap-grid="true"
    @snapGridLengthChange="snapGridLengthChange"
    :speed="628"
    :modules="modules"
    :mousewheel="{ forceToAxis: true }"
  >
    <swiper-slide class="swiper-slide">
      <div class="cell">
        <ImageHolder>
          <img src="@/assets/photos/shokunin.webp" alt="shokunin site de formation" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <div class="texte-p3 main-paragraph">
      <i18n-t class="paragraph" tag="p" :keypath="`events.trainingDescription`" scope="global">
        <template #link>
          <a class="link" href="https://shokunin.latelier.co" target="_blank">Shokunin</a>
        </template>
      </i18n-t>
    </div>
  </swiper>
</template>

<script>
import { Mousewheel } from "swiper";
import SubSwiper from "@/mixins/SubSwiper";
export default {
  name: "ContentTraining",
  mixins: [SubSwiper],
  setup() {
    return {
      modules: [Mousewheel],
    };
  },
};
</script>

<style scoped lang="scss">
.column-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 36px 0 0 36px;

  .paragraph {
    color: $white;
  }
}

.training-content {
  .dark-column-title {
    width: 100%;
  }

  .paragraph.inline {
    margin-bottom: 24px;
    width: 100%;
  }
}

.image-container {
  width: 100%;
  max-height: 70vh;

  img {
    object-fit: cover;
    width: 100%;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .training-content {
    flex-wrap: nowrap;
    justify-content: space-between;

    .image-container {
      width: 100%;
      flex-shrink: 0;
      margin-top: 40px;
    }

    .paragraph.inline {
      margin-bottom: 0;
      position: absolute;
      top: 40px;
      left: 20px;
    }

    .paragraph:last-child {
      width: 50%;
      margin-left: 20%;
      flex-shrink: 0;
    }
  }

  .column-content {
    height: auto;
    width: auto;
    overflow: auto;
    padding: 60px 20px;
    background: $dark-blue;
    position: relative;
  }

  .dark-column-title {
    display: none;
  }

  .image-container {
    margin-bottom: 24px;
  }
}
</style>
