<template>
  <ScrollSection>
    <div class="view">
      <div class="section-container" ref="sectionContainer">
        <div class="section-sizer">
          <div class="section-gabarit">
            <div class="container">
              <Logo name="Offer" />
              <!-- <SplitTextCover
                class="behavior main-title"
                ref="behavior22"
                data-behavior="22"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <h1 class="titre-h1">L’expertise et l’excellence technique au service<br />de votre business.</h1>
              </SplitTextCover> -->

              <Alpha
                class="behavior"
                ref="behavior22"
                data-behavior="22"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <Heading
                  :style="{
                    '--grid-column': `2 / span 6`,
                  }"
                />
              </Alpha>
              <Alpha
                class="behavior main-baseline crossed small-highlight-sentence"
                ref="behavior221"
                data-behavior="221"
                :style="{
                  '--grid-column': `2 / span 4`,
                  '--highlight-color': 'var(--brand-blue)',
                  'margin-top': '3em',
                  'margin-right': '5%'
                }"
              >
                <div class="small-highlight-sentence">
                  La mouvance du Software Craftsmanship est un ensemble de méthodes permettant aux
                  développeurs de produire du code à l'état de l’art.
                </div>
              </Alpha>

              <ImageHolder
                :style="{
                  '--grid-column': `8 / span 4`,
                  'margin-right': `var(--grid-negative-gutter)`,
                }"
                class="main-image behavior"
                ref="behavior2"
                data-behavior="2"
              >
                <img class="scaled-image" src="@/assets/photos/expertise-01.webp" alt="developper" />
              </ImageHolder>

              <Alpha
                class="behavior sub-baseline"
                ref="behavior2212"
                data-behavior="2212"
                :style="{
                  '--grid-column': `8 / span 4`,
                  '--highlight-color': 'var(--green)',
                  'margin-top': '1.8em',
                }"
              >
                <div class="medium-highlight-sentence">
                  Le Craftsmanship regroupe également<br />les valeurs de l’agilité :
                </div>
              </Alpha>

              <ImageHolder
                :style="{
                  '--grid-column': `2 / span 3`,
                  'align-self': 'start',
                }"
                class="behavior sub-image"
                ref="behavior217"
                data-behavior="217"
              >
                <img class="scaled-image" src="@/assets/photos/expertise-02.webp" alt="collaborer" />
              </ImageHolder>

              <TypingText
                class="behavior block sub-text"
                ref="behavior21245"
                data-behavior="21245"
                :style="{
                  '--grid-column': `6 / span 5`,
                  'margin-right': `var(--grid-negative-gutter)`,
                }"
              >
                <div class="highlight-sentence" style="font-size: var(--global--font-size-h2);">
                  Un produit ne doit pas seulement être fonctionnel mais doit être bien conçu et avoir un haut niveau de
                  qualité.
                </div>
              </TypingText>
            </div>
            <div class="container light-blue-layout" id="technos">
              <div
                class="title"
                :style="{
                  '--grid-column': `2 / span 6`,
                  'margin-bottom': '1em',
                }"
              >
                <TextCover class="behavior" ref="behavior311" data-behavior="311">
                  <h2 class="titre-h1">
                    {{ $t("technos.title") }}
                  </h2>
                </TextCover>
              </div>
              <Technos
                :style="{
                  '--grid-column': `2 / span 11`,
                }"
              />
            </div>
            <div class="container dark-layout" id="actions">
              <div
                class="title"
                :style="{
                  '--grid-column': `2 / span 6`,
                  'margin-bottom': '1em',
                }"
              >
                <SplitTextCover class="behavior" ref="behavior31221" data-behavior="31221">
                  <h2 class="titre-h1">
                    {{ $t("actions.title") }}
                  </h2>
                </SplitTextCover>
              </div>
              <Alpha
                class="behavior"
                ref="behavior229"
                data-behavior="229"
                :style="{
                  '--grid-column': `2 / span 4`,
                }"
              >
                <div class="texte-p1">
                  {{ $t("actions.actionsDescription") }}
                </div>
              </Alpha>
              <Actions
                ref="container-behave-1"
                :style="{
                  '--grid-column': `2 / span 10`,
                }"
              />
            </div>
            <div class="container" id="clients">
              <div
                class="title"
                :style="{
                  '--grid-column': `2 / span 6`,
                  'margin-bottom': '1em',
                }"
              >
                <TextCover class="behavior" ref="behavior1" data-behavior="1">
                  <h2 class="secondary-title">{{ $t("clients.title") }}</h2>
                </TextCover>
              </div>
              <Alpha
                class="behavior texte-p1 crossed"
                ref="behavior2291"
                data-behavior="2291"
                :style="{
                  '--grid-column': `2 / span 3`,
                }"
              >
                <div class="texte-p1">
                  {{ $t("clients.description") }}
                </div>
              </Alpha>
              <section
                class="clients-list"
                :style="{
                  '--grid-column': `6 / span 6`,
                }"
              >
                <Client
                  :client="client"
                  v-for="(client, i) in sortedClients"
                  :key="client.name"
                  class="client array-behave"
                  ref="array-behaveclient"
                  data-behavior="client"
                  :idx="i"
                  :data-idx="i"
                />
              </section>
              <RoundCta class="mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <RoundCta />
  </ScrollSection>
</template>

<script>
import ViewBehavior from "@/mixins/ViewBehavior";

import Logo from "@/components/Logo";
import Heading from "@/components/offer/Heading";
import Technos from "@/components/offer/Technos";
import Actions from "@/components/offer/Actions";
// import Expertises from "@/components/offer/Expertises";
import Client from "@/components/offer/Client";

import clients from "@/locales/clients.js";
import RoundCta from "@/components/RoundCta";

export default {
  name: "Offer",
  mixins: [ViewBehavior],
  components: {
    Logo,
    Heading,
    Technos,
    Actions,
    //   Expertises,
    Client,
    RoundCta
  },
  data() {
    return {
      clients,
    };
  },
  computed: {
    sortedClients() {
      return this.clients.slice().sort(() => 0.5 - Math.random());
    },
  },
};
</script>

<style scoped lang="scss">
.view {
  z-index: 2;
  // padding: 24px;
  // background: $green;
  --cover-color: #eef7ff;
}
.section-container {
  height: 100%;
}
.section-sizer {
  padding: var(--grid-min-gutter);
}
.section-gabarit {
  background: $white;
  // margin: var(--grid-min-gutter);
}

@media (max-width: $layout-breakpoint-medium) {
  .view {
    // padding: 12px 0 18px;
    z-index: 0;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      bottom: 0;
      width: 6px;
      background: $green;
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 0;
      width: 6px;
      background: $green;
      z-index: 1;
    }
  }
}

.clients-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.main-image {
  @include respond-to("xs-down") {
    grid-row: 1;
    margin-bottom: 42px;
    --grid-negative-gutter: 0px;
  }
}
.main-title {
  @include respond-to("xs-down") {
    margin-bottom: 164px;
  }
}
.main-baseline {
  @include respond-to("xs-down") {
    margin-bottom: 82px;
    .medium-highlight-sentence {
      padding-left: 25%;
    }
  }
}
.sub-baseline {
  @include respond-to("xs-down") {
    margin-bottom: 28px;
  }
}
.sub-text {
  @include respond-to("xs") {
    margin-bottom: 3.125vw !important;
  }
}
</style>
