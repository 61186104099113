import { ref } from 'vue'

function useNavigation () {
  const idx = ref(0);
  const swiper = ref(null);

  const previousable = ref(false);
  const nextable = ref(true);
  
  const handlePrevious = () => {
    swiper.value.slidePrev();
  };
  const handleNext = () => {
    swiper.value.slideNext();
  };
  const onSwiper = (instance) => {
    swiper.value = instance;
  };
  const slideChange = (instance) => {
    idx.value = instance.activeIndex;
    previousable.value = !swiper.value.isBeginning;
    nextable.value = !swiper.value.isEnd;
  };

  return {
    idx,
    swiper,
    previousable,
    nextable,
    handlePrevious,
    handleNext,
    onSwiper,
    slideChange
  }
}
export {
  useNavigation
}