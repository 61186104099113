<template>
  <div class="split-text-cover">
    <slot class="slot" />
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "SplitTextCover",
  methods: {
    beforeEnter() {
      gsap.set(this.$el.children, {
        opacity: 0
      });

    },
    handleEnter(i) {
      const stagger = 0.0628;
      const tl = new gsap.timeline(() => {
        // this.splits.revert();
      });
      tl
        .delay(i * 0.2)
        .to(this.$el.children, {
          opacity: 1,
          duration: 1,
          stagger,
          ease: "linear"
        }, "a")
    }
  }
};
</script>

<style scoped lang="scss">
.split-text-cover {
  &:deep(.word) {
    overflow: hidden;
    position: relative;
    margin-bottom: -0.4em;
    display: inline-block !important;
    // &:first-child {
    //   margin-top: -0.4em;
    // }
  }
  &:deep(.caption) {
    overflow: hidden;
    display: block;
    will-change: transform;
    padding-bottom: 0.3em;
    line-height: 1;
  }
  &:deep(.caption-helper) {
    display: block;
    will-change: transform;
  }
}

</style>
