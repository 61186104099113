<template>
  <div class="menu-overlay">
    <Logo class="LAtelier" />
    <div class="menu-mobile">
      <RouterLink
        :key="idx"
        :to="{ name }"
        v-for="({ name, label, bgColor }, idx) in slots"
        :style="{ 'background-color': `var(${bgColor})` }"
        class="titre-h3"
        @click="handleClick"
      >
        <span class="caption">
          <span class="caption-helper">{{ label }}</span>
        </span>
        <span class="text-cover" />
      </RouterLink>
    </div>
    <div class="container">
      <Contact />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Contact from "@/components/Contact";
export default {
  name: "MenuOverlay",
  components: {
    Logo,
    Contact,
  },
  props: {
    slots: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    handleClick() {
      this.$emit("closeMenu");
    }
  }
};
</script>

<style scoped lang="scss">
.menu-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 0;
  @include respond-to("xs-down") {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
}
.logo {
  @include respond-to("xs-down") {
    position: relative;
    margin-bottom: var(--vertical-margin);
    height: 25px;
    top: initial;
    left: initial;
    margin-top: 67px;
    margin-left: 24px;
    min-height: 25px;
  }
}
.contact {
  @include respond-to("xs") {
    grid-column: 11 / span 2;
  }
}
.container {
  @include respond-to("xs") {
    align-items: center;
    position: absolute;
    top: 50%;
    padding-top: 13.125vw;
    width: 100%;
    height: auto;
    bottom: 0;
    padding-bottom: 0;
  }
}
.menu-mobile {
  opacity: 0;
  margin-bottom: 30px;
  @include respond-to("xs") {
    display: none;
  }
  color: #ffffff;
  a {
    display: block;
    padding: 12px 24px;
  }
}
</style>
