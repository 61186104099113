<template>
  <swiper
    class="swiper-container sub-swiper"
    slides-per-view="auto"
    :snap-grid="true"
    @snapGridLengthChange="snapGridLengthChange"
    :speed="628"
    :modules="modules"
    :mousewheel="{ forceToAxis: true }"
  >
    <swiper-slide class="swiper-slide">
       <div class="cell">
        <ImageHolder>
          <img class="scaled-image" src="@/assets/photos/mentoring.webp" alt="image mentoring" />
        </ImageHolder>
      </div>
    </swiper-slide>
    <p class="texte-p3 main-paragraph">
      {{$t('events.mentoringDescription')}}
    </p>
  </swiper>
</template>

<script>
import { Mousewheel } from "swiper";
import SubSwiper from "@/mixins/SubSwiper";
export default {
  name: "ContentMentoring",
  mixins: [SubSwiper],
  setup() {
    return {
      modules: [Mousewheel]
    }
  },
};
</script>

<style scoped lang="scss">
.mentoring-content {
  flex-direction: column;
  align-items: center;

  .paragraph {
    align-self: flex-start;
    margin: 0 0 24px 10%;
  }

  .dark-column-title {
    align-self: flex-start;
  }

  .image-container {
    width: 80%;
  }
}

.column-content {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 36px 0 0 36px;

  .paragraph {
    color: $white;
  }
}

.image-container {
  margin-bottom: 52px;

  img {
    object-fit: contain;
    width: 100%;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .column-content {
    height: auto;
    width: auto;
    overflow: auto;
    padding: 60px 20px;
    background: $dark-blue;
    position: relative;
  }

  .dark-column-title {
    display: none;
  }

  .image-container {
    margin-bottom: 24px;
  }
}
</style>
