<template>
  <div class="footer">
    <div class="container">
      <div
        class="logo-footer"
        :style="{
          '--grid-column': `2 / span 2`,
        }"
      >
        <Logo />
        <div>
          <router-link class="link texte-p3 underline" :to="{ name: 'LAtelier' }">
            {{ $t("backToHome") }}
          </router-link>
        </div>
      </div>
      <Contact
        class="contact"
        :style="{
          '--grid-column': `10 / span 2`,
        }"
      />
    </div>
    <div class="mention">
      <TypingText class="behavior" ref="behavior44ww" data-behavior="44ww">
        <div class="texte-p3">
          {{ $t("footerMention") }}
        </div>
      </TypingText>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/svg/logo-blue-bg-tranparent.svg";

import Contact from "./Contact.vue";

export default {
  name: "Footer",
  components: {
    Contact,
    Logo,
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: $dark-blue;
  display: flex;
  align-items: center;
  position: relative;
  .container {
    padding-top: 4.6875vw;
    padding-bottom: 4.6875vw;
    @include respond-to("xs-down") {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.contact {
  position: static;
  margin: 0;
}

.logo-footer {
  display: flex;
  flex-direction: column;

  svg {
    @include respond-to("xs") {
      width: 13.33vw;
      height: 1.666vw;
    }
    @include respond-to("xs-down") {
      width: 214px;
      height: 25px;
      margin-bottom: 18px;
    }
  }
}

.link {
  color: $white;
  margin-top: 3.375em;
  @include respond-to("xs-down") {
    display: none;
  }

  &:hover {
    color: $green;
  }
}

.mention {
  position: absolute;
  left: var(--grid-min-gutter);
  bottom: var(--grid-min-gutter);
  color: $green;
}
</style>
