<template>
  <div class="helper" ref="container">
    <canvas class="experience" ref="canvas" />
  </div>
</template>

<script>
import Experience from "@/experience/index.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { inject, ref, onMounted, onUnmounted } from "vue";

import Device from "@/experience/Device";

export default {
  name: "Experience",
  setup() {
    const scroller = inject("scroller");
    const canvas = ref(null);
    let experience = null;
    let scrollTween = null;
    const container = ref(null);

    if (Device.width >= 835) {
      onMounted(() => {
        experience = new Experience(canvas.value);

        scrollTween = gsap.to(canvas.value, {
          y: "62.5%",
          ease: "none",
          scrollTrigger: {
            scroller: scroller.value,
            trigger: container.value,
            start: "top top",
            end: "bottom top",
            scrub: true,
            onUpdate: () => {
              if (ScrollTrigger.isInViewport(container.value)) {
                experience.wake();
                // experience.logo.tl.progress(progress)
              } else {
                experience.sleep();
              }
            },
          },
          onUpdate: () => {},
        });
      });
      onUnmounted(() => {
        if (experience) experience.unmounted();
        if (scrollTween) scrollTween.kill();
      });
    }

    return {
      canvas,
      container,
    };
  },
};
</script>

<style scoped lang="scss">
.helper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--brand-blue);
  @include respond-to("xs-down") {
    background-image: url("~@/assets/png/home.png");
    background-position: 50% 50%;
    background-size: cover;
    left: -24px;
    opacity: .4;
  }
}
.experience {
  width: 100%;
  height: 100%;
  display: block;
  @include respond-to("xs-down") {
    width: 0;
    height: 0;
    display: none;
  }
}
</style>
