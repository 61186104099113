import { LoadingManager, TextureLoader, LinearFilter, LinearMipMapLinearFilter } from 'three';

export default class Assets {
    constructor({
        onLoad = () => { }
    }) {
        const manager = new LoadingManager();
        manager.onLoad = () => {
            console.log('Loading complete!');
            onLoad();
        };
        manager.onProgress = (url, itemsLoaded, itemsTotal) => {
            console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
        };

        this.textureLoader = new TextureLoader(manager);
        
        this.textureLoader.load('models/croquis.png', (image) => {

            this.croquis = image;
            this.croquis.minFilter = LinearFilter
            this.croquis.magFilter = LinearMipMapLinearFilter
        })
    }
};
