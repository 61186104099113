<template>
  <div class="text-cover">
    <span class="caption" ref="caption">
      <span class="caption-helper" ref="captionHelper">
        <slot />
      </span>
    </span>
    <span class="cover" ref="cover" />
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "TextCover",
  methods: {
    beforeEnter() {
      gsap.set(this.$refs.caption, {
        x: "-100%"
      })
      gsap.set(this.$refs.captionHelper, {
        x: "100%"
      })
      gsap.set(this.$refs.cover, {
          x: "-100.5%"
      })
    },
    handleEnter(i) {
      if (!this.$refs.captionHelper || !this.$refs.caption) return
      const tl = new gsap.timeline()
      tl
        .delay(i * 0.2)
        .to(this.$refs.caption, {
          x: '0%',
          duration: 1,
          stagger: {
            amount: 0.15
          },
          ease: "expo.inOut"
        }, "a")
        .to(this.$refs.captionHelper, {
          x: '0%',
          duration: 1,
          stagger: {
            amount: 0.15
          },
          ease: "expo.inOut"
        }, "a")
        .to(this.$refs.cover, {
          x: '100%',
          duration: 1,
          stagger: {
            amount: 0.15
          },
          ease: "expo.inOut"
        }, "a")
        .set(this.$refs.cover, {
          autoAlpha: 0
        })
    }
  }
};
</script>

<style scoped lang="scss">
.text-cover {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  --cover-color: var(--green);
}
.caption {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  will-change: transform;
  line-height: 1.4;
}
.caption-helper {
  display: inline-block;
  white-space: nowrap;
  will-change: transform;
}
</style>
