import { createRouter, createWebHistory } from "vue-router";
import LAtelier from "../views/LAtelier.vue";
import Offer from "../views/Offer.vue";
import Consultants from "../views/Consultants.vue";
import Cookies from "../views/Cookies.vue";
import NotFound from "../views/NotFound.vue";
const routes = [
  {
    path: "/",
    name: "LAtelier",
    components: {
      LAtelier,
    },
  },
  {
    path: "/notre-offre",
    name: "Offer",
    components: {
      Offer
    },
  },
  {
    path: "/consultants",
    name: "Consultants",
    components: {
      Consultants
    },
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: Cookies,
    meta: { noOverlays: true, noCookieBanner: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { noOverlays: true, noCookieBanner: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
