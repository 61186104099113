<template>
  <div class="tool-container">
    <div class="tool-logo" :style="{ backgroundImage: `url(${toolLogo})` }"></div>
    <div class="tool-label">{{ toolName }}</div>
  </div>
</template>

<script>
export default {
  name: "Tool",
  props: {
    toolLogo: {
      type: String,
    },
    toolName: {
      type: String,
    },
  },
  data() {
    return {
      rotation: Math.random() * 360,
      lineH: Math.random() > 0.2,
      lineRight: Math.random() * 50,
      lineV: Math.random() > 0.2,
      lineTop: Math.random() * 50,
    };
  },
};
</script>

<style scoped lang="scss">
.tool-container {
  $radius: 8px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13vw;
  height: 10vw;
  top: 0;
  will-change: transform;
  @include respond-to("xs-down") {
    width: 120px;
    height: 120px;
    margin: 0 40px;
  }

  .tool-logo {
    $offset: 0.83vw;
    position: absolute;
    z-index: 10;
    top: $offset;
    left: $offset;
    right: $offset;
    bottom: $offset;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // transition: 0.2s ease-in;
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }

  .tool-label {
    z-index: 10;
    padding: 4px 8px;
    background: rgba($blue, 0.6);
    border-radius: 4px;
    color: $green;
    text-transform: uppercase;

    transform: translateY(4px) scale(0.8);
    opacity: 0;

    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }

  &:hover {
    .tool-logo {
      transform: scale(0.75);
      opacity: 0;
    }

    .tool-label {
      opacity: 1;
      transform: none;
    }
  }
}
</style>
