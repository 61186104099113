<template>
  <router-link class="logo" :to="{ name: 'LAtelier' }" :class="name">
    <LogoLAtelier class="logo-latelier" />
    <LogoConseil class="logo-conseil" />
  </router-link>
</template>

<script>
import LogoLAtelier from "@/assets/svg/logo-latelier.svg";
import LogoConseil from "@/assets/svg/logo-conseil.svg";

export default {
  name: "Logo",
  components: {
    LogoLAtelier,
    LogoConseil,
  },
  props: {
    name: {
      type: String,
      default: 'LAtelier'
    }
  }
};
</script>

<style scoped lang="scss">
.logo {
  position: absolute;
  z-index: 1;
  width: 13.33vw;
  height: 1.666vw;
  top: 3.125vw;
  left: 3.75vw;

  @include respond-to('xs-down') {
    top: 67px;
    left: 24px;
    width: 214px; 
    height: 25px; 
  }

  &.LAtelier {
    color: $white;
  }
  &.Offer {
    // left: calc(5% + 48px);
    color: $blue;
  }
  &.Consultants {
    // left: calc(5% + 72px);
    color: $blue;
  }
  &.Cookies {
    left: 5%;
  }
  &.NotFound {
    left: 5%;
    color: $white;
  }

  .logo-latelier {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .logo-conseil {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
</style>