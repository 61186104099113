<template>
  <div class="image-container agility">
    <img src="@/assets/png/philosophy/arrow-circle.png" :alt="'agility'" class="arrow" />
    <img src="@/assets/png/philosophy/circle-central.png" :alt="'agility'" class="circle" />
    <img src="@/assets/png/philosophy/loop.png" :alt="'agility'" class="loop" />
    <img src="@/assets/png/philosophy/line-agility.png" :alt="'agility'" class="line" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { inject } from "vue";

export default {
  mounted() {
    this.resetAnimations();
    const animation = this.handleEnter();
    const scroller = inject("scroller");
    this.st = ScrollTrigger.create({
      scroller: scroller.value,
      trigger: this.$el,
      start: "top+=100% bottom",
      end: 0,
      scrub: false,
      onToggle: self => {
        if (self.isActive) {
          animation.play();
        } else {
          animation.reverse();
        }
      }
    });
  },
  unmounted() {
    if(this.st) this.st.kill()
  },
  methods: {
    resetAnimations() {
      gsap.set(this.$el.querySelector(".circle"), {
        opacity: 0,
      });
      gsap.set(this.$el.querySelector(".arrow"), {
        opacity: 0,
      });
      gsap.set(this.$el.querySelector(".loop"), {
        opacity: 0,
      });
      gsap.set(this.$el.querySelector(".line"), {
        opacity: 0,
      });
    },
    handleEnter(i) {
      const tl = new gsap.timeline({
        paused: true
      });
      tl.fromTo(
        this.$el.querySelector(".circle"),
        {
          rotate: 180,
          opacity: 0,
        },
        {
          rotate: 0,
          opacity: 1,
          duration: 1.2,
          ease: "power2.out",
          delay: 0.2 * i,
        },
        "a"
      );

      tl.fromTo(
        this.$el.querySelector(".arrow"),
        {
          rotate: 180,
          opacity: 0,
        },
        {
          rotate: 0,
          opacity: 1,
          duration: 1.2,
          ease: "power2.out",
          delay: 0.2 * i,
        },
        "a"
      );

      tl.fromTo(
        this.$el.querySelector(".line"),
        {
          translateX: -64,
          opacity: 0,
        },
        {
          translateX: 0,
          opacity: 1,
          duration: 1.2,
          ease: "power2.out",
          delay: 0.2 * i,
        },
        "a"
      );
      tl.fromTo(
        this.$el.querySelector(".loop"),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1.2,
          ease: "power2.out",
          delay: 0.2 * i,
        },
        "a"
      );
      return tl;
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  width: 100%;
  margin-bottom: 72px;
  height: 200px;
  position: relative;
  overflow: visible;
  opacity: 1;

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &.agility {
    img {
      position: absolute;
      transform-origin: center;
    }

    .circle {
      opacity: 1;
      transform: scale(0.8);
    }

    .loop {
      opacity: 1;
      transform: scale(1.05);
    }

    .line {
      opacity: 1;
      bottom: -20px;
    }
  }
}
</style>
