<template>
  <section>
    <div class="content">
      <div class="technos">
        <div class="line technos-line">
          <div class="sentence texte-p1">
            <span>{{ $t("technos.backEnd") }}</span>
            <span>{{ $t("technos.frontEnd") }}</span>
            <span>{{ $t("technos.cloud") }}</span>
            <span>{{ $t("technos.devOps") }}</span>
          </div>
        </div>
      </div>
      <div class="divider-line"></div>
      <div class="tools">
        <div class="tool">
          <Tool v-for="{ name, logo } in toolSets" :key="name" :toolLogo="logo" :toolName="name" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Tool from "./Tool.vue";

import VueJS from "@/assets/png/technos/vuejs.png";
import Azure from "@/assets/png/technos/azure.png";
import Dotnet from "@/assets/png/technos/NET.png";
import Angular from "@/assets/png/technos/angular.png";
import Aws from "@/assets/png/technos/AWS.png";
import React from "@/assets/png/technos/react.png";
import Spring from "@/assets/png/technos/spring.png";
import Node from "@/assets/png/technos/nodejs.png";

export default {
  name: "Technos",
  components: {
    Tool,
  },
  data() {
    return {
      tools: [
        { logo: VueJS, name: "vuejs" },
        { logo: Azure, name: "azure" },
        { logo: Dotnet, name: "dotnet" },
        { logo: Angular, name: "angular" },
        { logo: Aws, name: "aws" },
        { logo: React, name: "react" },
        { logo: Spring, name: "spring" },
        { logo: Node, name: "nodejs" },
      ],
    };
  },
  computed: {
    toolSets() {
      return [...this.tools];
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -2em;
}

.technos-line {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  .sentence {
    will-change: transform;
    flex: 0 0 auto;
  }
}

.technos {
  color: $green;
  display: flex;
  align-items: center;
  width: 100%;
  grid-column: 1 / span 12;
  .titre-h1 {
    max-width: initial;
  }

  .line {
    display: flex;
    align-items: center;
    will-change: transform;

    .sentence {
      display: flex;
      margin-bottom: 0.618em;
      white-space: nowrap;
    }

    span {
      opacity: 0.4;
      &:not(:last-child) {
        &::after {
          content: "•";
          color: $green;
          margin: 0 4px;
        }
      }
    }
  }
}

.divider-line {
  height: 2px;
  width: 82.5vw;
  margin: 6.25vw 1.66vw 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 0;
}

.tools {
  grid-column: 1 / span 12;
  position: relative;
  margin-bottom: 3.14vw;
  @include respond-to("xs-down") {
    margin-bottom: 28px;
  }

  .tool {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include respond-to("xs-down") {
      justify-content: center;
    }
  }
}
</style>
