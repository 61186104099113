<template>
  <div
    class="image-holder"
  >
    <div ref="image" class="image">
      <slot />
    </div>
    <span class="cover bg-light-blue" ref="cover" />
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "ImageHolder",
  methods: {
    beforeEnter() {
      gsap.set(this.$refs.cover, {
        x: "-100.5%"
      });
      gsap.set(this.$refs.image, {
        x: "0%",
        alpha: 0,
      });
    },
    handleEnter(i) {
      const tl = new gsap.timeline()
      tl
        .delay(i * 0.2)
        .to(this.$refs.cover, {
          x: "100%",
          duration: 1,
          ease: "quint.inOut"
        }, 'a')
        .set(this.$refs.image, {
          alpha: 1,
        }, 'a+=0.5')
        .to(this.$refs.image, {
          x: "0%",
          duration: 0.5,
          ease: "quint.out"
        }, 'a+=0.5')
        .set(this.$refs.cover, {
          autoAlpha: 0,
        })
    }
  }
};
</script>

<style scoped lang="scss">
.image-holder {
  overflow: hidden;
  position: relative;
  --cover-color: #{$light-blue};
  &:deep(img) {
    width: 100%;
    display: block;
  }
}
.image {
  will-change: transform;
}
</style>
