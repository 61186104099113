<template>
  <div class="view cookies">
    <div class="content">
      <h1>Charte sur la gestion des cookies</h1>
      <div class="container">
        <p>L'Atelier met en oeuvre des traceurs, communément appelés cookies, sur son site.</p>
      </div>
      <h2>Qu'est-ce qu'un cookie ?</h2>
      <div class="container">
        <p>
          Un cookie est un petit fichier texte sauvegardé sur l'ordinateur ou le téléphone mobile du Visiteur ou de
          l'Utilisateur qui consulte le Site. Il contient plusieurs données :
        </p>
        <ul>
          <li>Le nom du serveur qui l'a déposé ;</li>
          <li>Un identifiant sous forme de numéro unique ;</li>
          <li>Éventuellement une date d'expiration.</li>
        </ul>
      </div>
      <h2>A quoi servent les cookies émis sur notre site ?</h2>
      <div class="container">
        <p>Les cookies utilisés sur le site permettent :</p>
        <ul>
          <li>
            D'accéder, par voie de transmission électronique, à des informations déjà stockées dans l'équipement
            (smartphone, tablettes, ordinateur, etc.) utilisé par le Visiteur ou l'Utilisateur lors de sa navigation sur
            le Site afin d'identifier les services et rubriques que l'Utilisateur et/ou le Visiteur a visité, et plus
            généralement son comportement en matière de visites. Ils mesurent le nombre de visites, le nombre de pages
            vues ainsi que son activité et sa fréquence de retour ;
          </li>
          <li>
            À inscrire des informations dans cet équipement (notamment, le fichier permettant à l'Utilisateur de rester
            connecté sur son Compte) ;
          </li>
        </ul>
        <p>
          Ces informations sont utiles pour faciliter la navigation sur le Site et l'adapter à ses besoins. Des cookies
          sont également nécessaires au bon fonctionnement de certains services, notamment en matière de protocole de
          sécurisation de sa visite.
        </p>
      </div>
      <h2>Quels types de cookies sont utilisés sur notre site ?</h2>
      <div class="container">
        <p>
          Les Visiteurs et Utilisateurs sont informés que les cookies déposés et/ou lus sur leur équipement peuvent être
          les suivants :
        </p>
        <ul>
          <li>Les cookies dits « essentiels » ou « nécessaires »</li>
        </ul>
        <p>
          Ces cookies permettent de naviguer sur le Site et sont indispensables au bon fonctionnement du Site. Leur
          désactivation entraînera des difficultés dans l'utilisation du Site et aura pour conséquence d'empêcher
          l'accès à certaines fonctionnalités.
        </p>
        <p>
          Ces cookies servent notamment à adapter la présentation du site à vos préférences d'affichage du terminal
          (langue utilisée, résolution d'affichage, système d'exploitation utilisé, etc…), à mémoriser des informations
          relatives à vos identifiant ou encore à mettre en œuvre des mesures de sécurité, par exemple lorsqu'il vous
          est demandé de vous connecter à nouveau à un contenu ou à un service après une certaine durée écoulée. Ces
          cookies sont gérés par votre navigateur. L'Atelier n'opère pas ce type de cookies.
        </p>
        <ul>
          <li>Les cookies dits de « performance » et de « statistique »</li>
        </ul>
        <p>
          Ces cookies recueillent des renseignements sur la manière dont le Visiteur et/ou l'Utilisateur utilise le
          Site. Ils permettent notamment d'identifier les pages qu'ils consultent le plus souvent et les messages
          d'erreur qui apparaissent et d'établir des statistiques et volume de fréquentation et d'utilisation des divers
          éléments composant le Site (rubriques et contenus visité, parcours) afin d'améliorer l'intérêt et l'ergonomie
          des pages et des services proposés sur le Site.
        </p>
        <p>
          Ces cookies permettent à d'identifier et de résoudre les problèmes inhérents au fonctionnement du Site de
          l'améliorer.
        </p>
        <p>
          <strong>
            L'Atelier vous demande votre accord avant l'activation des cookies de Google analytics. En cas de refus ou
            de poursuite de la navigation, le cookie de Google analytics ne sera pas activé.
          </strong>
        </p>
      </div>
      <h2>Quelle est la durée de vie des cookies générés par notre site ?</h2>
      <div class="container">
        <p>Vous trouverez ci-après les cookies utilisés, leur description et les durées de conservation afférentes :</p>
        <table>
          <thead>
            <tr>
              <td>Nom du cookie</td>
              <td>Source</td>
              <td>Durée de conservation</td>
              <td>Description</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cookie in cookies" :key="cookie.name">
              <td>{{ cookie.name }}</td>
              <td>{{ cookie.source }}</td>
              <td>{{ cookie.duration }}</td>
              <td>{{ cookie.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Comment exprimer vos choix par rapport à ces cookies ?</h2>
      <div class="container">
        <p>
          Les Visiteurs et Utilisateurs peuvent refuser le recours à des cookies ou, après avoir donné leur
          consentement, retirer partiellement ou totalement ce consentement par le biais des paramètres de leur logiciel
          de navigation s'agissant des cookies essentiels. La configuration de chaque logiciel de navigation est
          différente. Elle est décrite dans le menu d'aide du navigateur du Visiteur et/ou de l’Utilisateur, qui permet
          de savoir de quelle manière modifier ses souhaits en matière de cookies.
        </p>
        <p>
          Les Visiteurs et Utilisateurs peuvent ainsi configurer leur logiciel de navigation de manière à ce que des
          cookies soient enregistrés dans leur terminal ou, au contraire, qu'ils soient rejetés, soit systématiquement,
          soit selon leur émetteur. Ils peuvent également configurer leur logiciel de navigation de manière à ce que
          l'acceptation ou le refus des cookies leur soient proposés ponctuellement, avant qu'un cookie soit susceptible
          d'être enregistré dans leur terminal.
        </p>
      </div>

      <div class="cookies-container">
        <div class="line" v-for="cookie in cookiesValidation" :key="cookie.name">
          <div class="description-container">
            <div class="name">{{ cookie.name }}</div>
            <div class="description">{{ cookie.description }}</div>
          </div>
          <div
            class="checkbox-container"
            :class="{ active: cookie.active, forced: cookie.forced }"
            role="checkbox"
            :aria-checked="cookie.active"
            tabindex="0"
            @click="toggleCookie(cookie)"
            @keypress.enter="toggleCookie(cookie)"
          >
            <div class="checkbox"></div>
            <label class="checkbox-label">{{ cookie.active ? "Activé" : "Désactivé" }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  data() {
    return {
      cookies: [
        {
          name: "_ga",
          source: "Google Analytics",
          duration: "1 année",
          description:
            "Enregistre un identifiant unique utilise pour générer des données statistiques sur la façon dont le visiteur utilise le site.",
        },
        {
          name: "_gat",
          source: "Google Analytics",
          duration: "1 année",
          description: "Utilise par Google Analytics pour diminuer radicalement le taux de requêtes.",
        },
        {
          name: "_gid",
          source: "Google Analytics",
          duration: "1 année",
          description:
            "Enregistre un identifiant unique utilise pour générer des données statistiques sur la façon dont le visiteur utilise le site.",
        },
      ],
      cookiesValidation: [
        {
          name: "Cookies techniques nécessaires au bon fonctionnement du site",
          description:
            "Utilisation: Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée.",
          active: true,
          forced: true,
        },
        {
          name: "Google Analytics : mesure d'audience et d'utilisation du site",
          description:
            "Utilisation: Ils permettent d'obtenir des statistiques de fréquentation anonymes du site afin d'optimiser son ergonomie, sa navigation et ses contenus.",
          active: true,
          forced: false,
        },
      ],
    };
  },
  methods: {
    toggleCookie(cookie) {
      if (!cookie.forced) {
        cookie.active = !cookie.active;

        if (cookie.active) {
          this.$gtag.optIn();
        } else {
          this.$gtag.optOut();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.view.cookies {
  padding: 128px 0 96px;
  background: $white;

  h1 {
    color: $blue;
    font-family: var(--title-font);
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  h2 {
    color: $dark-blue;
    font-family: var(--title-font);
    font-size: 24px;
    font-weight: bold;
    margin: 24px 0 16px;
  }

  .container {
    color: #777;
    line-height: 24px;

    p {
      margin: 2px 0 8px;
    }

    ul {
      list-style: none;

      li {
        padding-left: 8px;

        &::before {
          content: "-";
          margin-right: 4px;
        }
      }
    }
  }

  .cookies-container {
    margin: 24px 0;

    .line {
      color: $dark-blue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 0;
      padding: 16px 24px;
      background: #f4f5f6;

      .description-container {
        padding-right: 24px;

        .name {
          font-weight: 700;
          margin: 0 0 8px;
        }
      }

      .checkbox-container {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 64px;
        margin-top: 4px;
        // cursor: pointer
cursor: none;

        .checkbox {
          position: relative;
          flex-shrink: 0;
          width: 64px;
          height: 32px;
          background: #e9ebed;
          border-radius: 2px;
          outline: solid 2px transparent;
          transition: 0.3s ease-in-out;

          &::after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            width: 28px;
            border: solid 2px $dark-blue;
            background: transparent;
            border-radius: 2px;
            box-sizing: border-box;
            transition: 0.3s ease-out;
          }
        }

        .checkbox-label {
          font-size: 12px;
          margin-top: 4px;
        }

        &.forced {
          opacity: 0.8;
          cursor: not-allowed;
        }

        &.active {
          .checkbox {
            &::after {
              left: calc(50% + 2px);
              background: $dark-blue;
            }
          }

          .checkbox-label {
            font-weight: 700;
          }
        }

        &:hover:not(.forced) {
          .checkbox {
            outline: solid 2px $light-blue;
          }
        }

        &:active:not(.forced) {
          .checkbox {
            outline: solid 2px transparent;

            &::after {
              transform: scale(0.9);
            }
          }
        }

        &:focus-visible:not(.forced) {
          .checkbox {
            outline: solid 2px $light-blue;
          }
        }
      }
    }
  }

  table {
    margin: 16px 0 24px;
    border-right: solid 2px #f4f5f6;
    border-left: solid 2px #f4f5f6;

    thead {
      font-weight: 700;
      background: #f4f5f6;
    }

    tr {
      td {
        padding: 12px;
        vertical-align: middle;
        line-height: 24px;
        border-bottom: solid 2px #f4f5f6;

        strong {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
