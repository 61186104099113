import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueGtag from "vue-gtag";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from 'gsap/InertiaPlugin';
gsap.registerPlugin(ScrollTrigger, Flip, SplitText, ScrollToPlugin, ScrollSmoother, Draggable, InertiaPlugin);

import ImageHolder from "@/components/ImageHolder";
import PinHolder from "@/components/PinHolder";
import TextCover from "@/components/TextCover";
import SplitTextCover from "@/components/SplitTextCover";
import SplitLines from "@/components/SplitLines";
import TypingText from "@/components/TypingText";
import Alpha from "@/components/Alpha";
// import PixelImage from "@/components/PixelImage";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG, enabled: false },
  },
  router
);

app.mount("#app");
app
  .component("ImageHolder", ImageHolder)
  .component("PinHolder", PinHolder)
  .component("TextCover", TextCover)
  .component("SplitTextCover", SplitTextCover)
  .component("SplitLines", SplitLines)
  .component("TypingText", TypingText)
  .component("Alpha", Alpha);
// .component('PixelImage', PixelImage);
