import { createStore } from "vuex";

export default createStore({
  state: {
    isMobileDevice: false,
    cookiesValidated: localStorage.getItem("cookies"),
  },
  mutations: {
    mobileDevice(state, value) {
      state.isMobileDevice = value;
    },
    cookies(state, value) {
      state.cookiesValidated = value;

      if (value) {
        localStorage.setItem("cookies", value);
      }
    },
  },
  actions: {
    setCookies({ commit }, validated) {
      commit("cookies", validated);
    },
    setMobileDevice({ commit }) {
      const isMobile = window.matchMedia("(max-width: 834px)").matches;
      commit("mobileDevice", isMobile);
    },
  },
  modules: {},
  getters: {
    isMobileDevice: (state) => {
      return state.isMobileDevice;
    },
    cookiesValidated: (state) => {
      return state.cookiesValidated;
    },
  },
});
