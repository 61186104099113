<template>
  <div class="navigation">
    <div
      :class="[
        'controls previous',
        {
          active: previousable,
        },
      ]"
      @click="handlePrevious"
    >
      <Arrow />
    </div>
    <div
      :class="[
        'controls next',
        {
          active: nextable,
        },
      ]"
      @click="handleNext"
    >
      <Arrow />
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/svg/arrow.svg";
export default {
  name: "Navigation",
  components: {
    Arrow
  },
  props: {
    previousable: {
      type: Boolean,
      default: false
    },
    nextable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const handlePrevious = () => {
      emit("previous");
    };
    const handleNext = () => {
      emit("next");
    };
    return {
      handlePrevious,
      handleNext,
    };
  },
};
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 2.5vw;
  margin-right: 8.33%;
  column-gap: 1.5em;
  @include respond-to("xs-down") {
    width: 120px;
    margin-left: auto;
    left: 0;
    justify-content: space-between;
  }
}
.controls {
  // font-size: 3em;
  height: 3em;
  width: 2em;
  color: var(--green);
  user-select: none;
  // cursor: pointer
cursor: none;
  opacity: 0.3;
  svg {
    width: 100%;
    height: 100%;
  }
  &.active {
    opacity: 1;
  }
}
.previous {
  svg {
    transform: rotate(-180deg);
  }
}
</style>
