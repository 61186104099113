<template>
  <ScrollSection ref="scrollSection">
    <div class="view">
      <div class="section-container" ref="sectionContainer">
        <div class="section-sizer">
          <div class="section-gabarit">
            <div class="container">
              <Logo name="Consultants" />
              <div
                class="title main-title"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <TextCover class="behavior" ref="behavior1" data-behavior="1">
                  <h1 class="titre-h1">Nos consultant•es</h1>
                </TextCover>
              </div>
              <Alpha
                class="behavior main-baseline"
                ref="behavior22"
                data-behavior="22"
                :style="{
                  '--grid-column': `3 / span 4`,
  
                }"
              >
                <div class="medium-highlight-sentence">
                  Rejoindre L’Atelier, c’est rejoindre une tribu de passionné•es où les moyens sont mis en place
                  pour accompagner nos consultant•es et leur permettre de s’épanouir dans leur quotidien.
                </div>
              </Alpha>
              <ImageHolder
                :style="{
                  '--grid-column': `8 / span 4`,
                }"
                class="behavior main-image"
                ref="behavior2"
                data-behavior="2"
              >
                <img class="image" src="@/assets/photos/consultants-02.webp" alt="accompagner" />
              </ImageHolder>
            </div>
            <div class="container">
              <div
                class="texte-p1"
                :style="{
                  '--grid-column': `8 / span 4`,
                }"
              >
                Ils se démarquent par une implication personnelle forte, pouvant se traduire de différentes façons :
                <ul>
                  <li class="paragraph">{{ $t("team.curiosity") }}</li>
                  <li class="paragraph">{{ $t("team.personalProjects") }}</li>
                  <li class="paragraph">{{ $t("team.openSource") }}</li>
                  <li class="paragraph">{{ $t("team.articles") }}</li>
                  <li class="paragraph">{{ $t("team.meetups") }}</li>
                  <li class="paragraph">{{ $t("team.speakers") }}</li>
                  <li class="paragraph">{{ $t("team.trainor") }}</li>
                </ul>
              </div>
              <Alpha
                class="behavior"
                ref="behavior222"
                data-behavior="222"
                :style="{
                  '--grid-column': `8 / span 4`,
                  'padding-right': '5%',
                  'margin-bottom': '6.25vw'
                }"
              >
                <div class="medium-highlight-sentence">
                  Dans ce mindset de progression continue, nos développeurs sont friands d'apprentissage et de partage.<br />Nous
                  leur permettons d'y accéder<br />à travers la Tribu.
                </div>
              </Alpha>
              <ImageHolder
                :style="{
                  '--grid-column': `2 / span 4`,
                  'grid-row': `1 / auto`,
                  'align-self': 'flex-start',
                }"
                class="behavior"
                ref="behavior3"
                data-behavior="3"
              >
                <img class="image" src="@/assets/photos/consultants-01.webp" alt="accompagner" />
              </ImageHolder>
            </div>
            <div class="container dark-layout"  id="events">
              <div
                class="title"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <TextCover class="behavior" ref="behavior11" data-behavior="11">
                  <h1 class="titre-h1">La tribu</h1>
                </TextCover>
              </div>
              <Alpha
                class="behavior"
                ref="behavior2125"
                data-behavior="2125"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <div class="small-highlight-sentence">
                  L’Atelier c’est avant-tout une communauté de technophiles et d’experts pour te permettre de partager et
                  progresser techniquement à travers différents dispositifs et événements.
                </div>
              </Alpha>
              <div
                :style="{
                  '--grid-column': `2 / span 11`,
                }"
              >
                <Events />
              </div>
              <div
                id="community"
                class="title"
                :style="{
                  'padding-top': '3.1vw',
                  'margin-top': '-3.1vw',
                  '--grid-column': `2 / span 6`,
                }"
              >
                <TextCover class="behavior" ref="behavior111" data-behavior="111">
                  <h1 class="titre-h1">Les contributions</h1>
                </TextCover>
              </div>
              <Alpha
                class="behavior"
                ref="behavior2124"
                data-behavior="2124"
                :style="{
                  '--grid-column': `2 / span 6`,
                }"
              >
                <div class="small-highlight-sentence">
                  Parce que chacun à sa hauteur peut participer au fonctionnement et développement de l’entreprise en
                  fonction de ses appétences, tu peux également endosser un ou plusieurs rôles définis au service de la
                  tribu.
                </div>
              </Alpha>
              <div
                :style="{
                  '--grid-column': `2 / span 3`,
                }"
              >
                <Community
                />
              </div>
              <TypingText
                class="behavior block"
                ref="behavior21245"
                data-behavior="21245"
                :style="{
                  '--grid-column': `2 / span 5`,
                }"
              >
                <div class="highlight-sentence">Apprendre et transmettre<br />sont les piliers de notre tribu</div>
              </TypingText>
            </div>
            <RSE ref="container-behave-2" id="RSE"/>
            <Footer ref="container-behave-3"/>
          </div>
        </div>
      </div>
    </div>
    <RoundCta/>
  </ScrollSection>
</template>

<script>

import ViewBehavior from "@/mixins/ViewBehavior";
import Logo from "@/components/Logo";
import Events from "@/components/consultants/Events";
import Community from "@/components/consultants/Community";
import RSE from "@/components/consultants/RSE";
import Footer from "@/components/Footer";
import RoundCta from "@/components/RoundCta";

export default {
  name: "Consultants",
  mixins: [ViewBehavior],
  components: {
    Logo,
    Events,
    Community,
    RSE,
    Footer,
    RoundCta,
  }
};
</script>

<style scoped lang="scss">
.section-container {
  height: 100%;
  width: 100%;
}
.section-sizer {
  padding: var(--grid-min-gutter);
  --cover-color: #eef7ff;
}
.section-gabarit {
  background-color: var(--white);
}
.title {
  & + .split-text-cover {
    @include respond-to("xs") {
      margin-top: 2.9em;
    }
  }
}
.dark-layout .title + .split-text-cover {
  @include respond-to("xs") {
    margin-top: 0;
  }
}
.main-title {
  @include respond-to("xs") {
    margin-top: 20%;
  }
}
.main-image {
  @include respond-to("xs") {
    margin-top: -60%;
  }
  @include respond-to("xs-down") {
    grid-row: 2;
    margin-top: 42px;
    margin-bottom: 42px;
  }
}
.main-baseline {
  @include respond-to("xs-down") {
    .medium-highlight-sentence {
      padding-right: 12.5%;
    }
  }
}
ul {
  @include respond-to("xs") {
    margin-right: 25%;
  }
}
</style>
