<template>
  <div class="container paper">
    <div
      class="title"
      :style="{
        '--grid-column': `2 / span 6`,
        'margin-bottom': '0.5em'
      }"
    >
      <SplitTextCover class="behavior" ref="behavior1111" data-behavior="1111">
        <h1 class="titre-h1">Nos engagements :</h1>
      </SplitTextCover>
    </div>
    <Alpha
      class="behavior"
      ref="behavior212"
      data-behavior="212"
      :style="{
        '--grid-column': `2 / span 6`,
      }"
    >
      <div class="small-highlight-sentence">
        Nous rejoindre, c’est aussi rejoindre une entreprise responsable qui respecte des engagements qui lui sont
        chers. Depuis maintenant plusieurs années, nous développons une stratégie RSE et ce, à plusieurs niveaux :
      </div>
    </Alpha>
    <div class="section">
      <div
        class="block"
        :style="{
          '--grid-column': `2 / span 4`,
        }"
      >
        <!-- <SplitTextCover class="behavior" ref="behavior11111" data-behavior="11111"> -->
        <i18n-t class="brush-title" tag="h3" keypath="rse.social" scope="global">
          <template #line>
            <div class="drawing-line"></div>
          </template>
          <template #chevronouvrant>&lt;</template>
        </i18n-t>
        <!-- </SplitTextCover> -->
        <div class="texte-p2">
          <p>{{ $t("rse.parityDescription") }}</p>
          <p>{{ $t("rse.socialDescription") }}</p>
        </div>
      </div>
      <div
        class="column stats-column"
        :style="{
          '--grid-column': `8 / span 4`,
        }"
      >
        <div class="circle">
          <img src="@/assets/png/rse/surround.png" alt="circle" />
        </div>
        <i18n-t class="stats texte-p3" tag="div" keypath="rse.womenStats" scope="global">
          <template #stat>
            <div class="number"><span class="parity-value">42</span>%<sup>*</sup></div>
          </template>
          <template #bold>
            <div class="subtitle medium-highlight-sentence">{{ $t("rse.womenStatsBold") }}</div>
          </template>
        </i18n-t>
      </div>
    </div>
    <div
      :style="{
        '--grid-column': `5 / span 2`,
        'margin-bottom': '-6vw',
        'margin-top': '-5vw',
      }"
      class="image-helper coffee"
    >
      <img src="@/assets/png/rse/coffee_beans.png" alt="coffee" />
    </div>
    <div class="section">
      <PinHolder
        :style="{
          '--grid-column': `2 / span 3`,
          transform: 'rotate(-3deg)',
          'z-index': 1,
        }"
        class="behavior snack-holder"
        ref="behavior44"
        data-behavior="44"
      >
        <img class="picture" src="@/assets/png/rse/snack.webp" alt="snack" />
      </PinHolder>
      <PinHolder
        :style="{
          '--grid-column': `3 / span 4`,
          transform: 'rotate(-12deg)',
          'margin-top': '-10%',
          'align-self': 'flex-start',
        }"
        class="behavior video-game"
        ref="behavior4"
        data-behavior="4"
      >
        <img class="picture" src="@/assets/png/rse/video-game.webp" alt="office" />
      </PinHolder>
      <div
        class="well-being"
        :style="{
          '--grid-column': `8 / span 4`,
        }"
      >
        <!-- <SplitTextCover class="behavior" ref="behavior112111" data-behavior="112111"> -->
        <i18n-t class="brush-title" tag="h3" keypath="rse.wellBeing" scope="global">
          <template #line>
            <div class="drawing-line"></div>
          </template>
          <template #chevronouvrant>&lt;</template>
        </i18n-t>
        <!-- </SplitTextCover> -->
        <div class="texte-p2">
          <p>{{ $t("rse.wellBeingOfficeDescription") }}</p>
          <p>{{ $t("rse.wellBeingDescription") }}</p>
        </div>
      </div>
      <img
        class="logo-ab"
        src="@/assets/png/rse/bio_logo.png"
        alt="label Agriculture Biologique"
      />
    </div>
    <div
      :style="{
        '--grid-column': `9 / span 4`,
      }"
      class="image-helper leaves"
    >
      <img src="@/assets/png/rse/leaves.png" alt="leaves" />
    </div>
    <div
      :style="{
        '--grid-column': `2 / span 4`,
      }"
      class="image-helper earth"
    >
      <img src="@/assets/png/rse/earth.png" alt="earth" />
    </div>
    <div
      class="block"
      :style="{
        '--grid-column': `8 / span 4`,
        'grid-row': `0 / span 1`,
        'align-self': 'flex-start',
        'margin-right': `var(--grid-negative-gutter)`,
      }"
    >
      <!-- <TextCover class="behavior" ref="behavior311" data-behavior="311"> -->
      <i18n-t class="brush-title" tag="h3" keypath="rse.environment" scope="global">
        <template #line>
          <div class="drawing-line"></div>
        </template>
        <template #chevronouvrant>&lt;</template>
      </i18n-t>
      <!-- </TextCover> -->
      <div class="texte-p2">
        <p>Pas de papier à L'Atelier / Politique 0 papier</p>
        <p>Tri des déchets et recyclage via une société spécialisée - partenariat avec les Joyeux Recycleurs</p>
        <p>
          Circuit court pour l'approvisionnement de nos fruits. Le primeur de notre rue nous apporte des fruits de
          saison chaque semaine
        </p>
        <p>Café et snack 100% bio. Partenariat avec Brâam</p>
        <p>
          Locaux aux dernières normes environnementales (HQE). Très basse consommation d'énergie (exemple : pas de
          chauffage ni de climatisation mais un système de poutre d'eau chaude / froide
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RSE",
};
</script>

<style scoped lang="scss">
.paper {
  background-image: url("~@/assets/png/rse/white_paper_texture.png");
  background-repeat: repeat;
  background-position: center;
  background-size: 10%;
}
.image-helper {
  img {
    display: block;
    width: 100%;
  }
}

.stats-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    height: 100%;
    width: 130%;
    transform: translateX(-50%) translateY(-45%);

    img {
      object-fit: cover;
      width: 100%;
    }
  }
}

:deep(.stats) {
  color: $light-blue;
  display: flex;
  flex-direction: column;
  position: relative;
  letter-spacing: 0;

  .number {
    font-size: var(--global--font-number);
    margin-bottom: 0em;
    position: relative;
    font-weight: 600;
    font-family: var(--title-font);
    sup {
      transform: scale(0.625);
      display: inline-block;
      transform-origin: 25% 25%;
    }
    .parity-value {
      display: inline-block;
    }
  }

  .subtitle {
    margin-bottom: 0em;
    letter-spacing: 0;
  }
}

.well-being {
  margin-top: 48px;
  @include respond-to("xs") {
    grid-row: 0 / span 1;
    align-self: flex-start;
    margin-right: var(--grid-negative-gutter);
    margin-top: -7.5vw;
  }
}

.coffee {
  @include respond-to("xs-down") {
    width: 40%;
    margin-left: auto;
  }
}
.leaves {
  @include respond-to("xs") {
    margin-top: -7.5vw;
  }
  @include respond-to("xs-down") {
    margin-top: -7.5vw;
    margin-right: -20vw;
  }
}
.earth {
  @include respond-to("xs-down") {
    grid-row: 100;
    margin-top: 24px;
  }
}
.brush-title + .texte-p2 {
  padding-right: 20%;
}
.logo-ab {
  @include respond-to("xs-down") {
    display: none;
  }
  --grid-column: 2 / span 4;
  width: 5.375em;
  height: auto;
  margin-top: 2em;
}
.snack-holder {
  margin-left: -7.5%;
  margin-right: 7.5%;
}
.video-game {
  margin-left: 6%;
  margin-right: -6%;
  z-index: 1;
}
</style>
