import  {
    Vector2 
} from "three"

export default {
    width: window.innerWidth,
    height: window.innerHeight,
    pixelRatio: Math.min(2.0, window.devicePixelRatio),
    isSafari: !! navigator.userAgent.match( /Safari/i ) && ! navigator.userAgent.match( /Chrome/i ),
    mouse: new Vector2(0, 0)
};