<template>
  <div class="menu-button" @click.stop="toggleMenu" @mouseover="hoverMenuAnimation" :class="$route.name">
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>menu</title>
      <g id="burger-menu" stroke="CurrentColor" stroke-width="2" stroke-linecap="round">
        <line x1="2" y1="4" x2="2" y2="12" id="left" />
        <line x1="8" y1="2" x2="8" y2="14" id="center" />
        <line x1="14" y1="5" x2="14" y2="11" id="right" />
      </g>
    </svg>
    <div class="label-container">
      <span id="menu-label" :class="{ active: !menuOpen }" :data-before="$t('notice')">
        {{ $t("notice") }}
      </span>
      <span id="close-label" :class="{ active: menuOpen }" :data-before="$t('close')">
        {{ $t("close") }}
      </span>
    </div>
  </div>
</template>

<script>
import { state } from "@/components/PullComponent/state";
import gsap from "gsap";

export default {
  name: "MenuButton",
  emits: ["toggleMenu"],
  props: {
    menuOpen: {
      type: Boolean,
    },
  },
  mounted() {
    this.enterMenuAnimation();
  },
  watch: {
    menuOpen(open) {
      state.updateDirection();
      if (open) {
        this.openMenuAnimation();
      } else {
        this.closeMenuAnimation();
      }
    },
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
    hoverMenuAnimation() {
      if (this.isAnimating) return;
      this.timeline = gsap.timeline({
        onComplete: () => {
          this.isAnimating = false;
        },
      });
      this.isAnimating = true;
      if (this.menuOpen) {
        this.timeline.fromTo(
          "#left",
          {
            attr: {
              x1: 8,
              y1: 8,
              x2: 8,
              y2: 8,
            },
          },
          {
            attr: {
              x1: 2,
              y1: 2,
              x2: 14,
              y2: 15,
              duration: 1.4,
            },
          },
          "<0.1"
        );

        this.timeline.fromTo(
          "#right",
          {
            attr: {
              x1: 8,
              y1: 8,
              x2: 8,
              y2: 8,
            },
          },
          {
            attr: {
              x1: 2,
              y1: 15,
              x2: 14,
              y2: 2,
              duration: 1.4,
            },
          },
          "<0.1"
        );
      } else {
        //step 1

        this.timeline.to(
          "#left",
          {
            attr: {
              x1: 6,
              x2: 6,
              y1: 3,
              y2: 11,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#center",
          {
            attr: {
              x1: 10,
              x2: 10,
              y1: 3,
              y2: 11,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        //step 2

        this.timeline.to(
          "#left",
          {
            attr: {
              x1: 2,
              x2: 2,
              y1: 4,
              y2: 10,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#center",
          {
            attr: {
              x1: 8,
              x2: 8,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        //step 3

        this.timeline.to(
          "#left",
          {
            attr: {
              y1: 6,
              y2: 8,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#center",
          {
            attr: {
              x1: 6,
              x2: 6,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#right",
          {
            attr: {
              x1: 10,
              x2: 10,
              y1: 3,
              y2: 11,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        // // reset initial position \\ \\

        this.timeline.to(
          "#left",
          {
            attr: {
              x1: 2,
              x2: 2,
              y1: 4,
              y2: 12,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#center",
          {
            attr: {
              x1: 8,
              x2: 8,
              y1: 2,
              y2: 14,
            },
            duration: 0.4,
          },
          "<0.1"
        );

        this.timeline.to(
          "#right",
          {
            attr: {
              x1: 14,
              x2: 14,
              y1: 5,
              y2: 11,
            },
            duration: 0.4,
          },
          "<0.1"
        );
      }
    },
    enterMenuAnimation() {
      gsap.from(".menu-button", {
        opacity: 0,
        x: -16,
        duration: 0.4,
        delay: 0.8,
      });

      gsap.from("#left", {
        attr: {
          x1: -2,
          x2: -2,
        },
        duration: 0.4,
        delay: 0.8,
      });

      gsap.from("#center", {
        attr: {
          x1: -2,
          x2: -2,
        },
        duration: 0.4,
        delay: 0.9,
      });

      gsap.from("#right", {
        attr: {
          x1: -2,
          x2: -2,
        },
        duration: 0.4,
        delay: 1,
      });
    },
    openMenuAnimation() {
      this.isAnimating = true;
      this.timeline.pause();

      gsap.to("#left", {
        attr: {
          x1: 2,
          y1: 2,
          x2: 14,
          y2: 15,
          duration: 1.4,
        },
      });

      gsap.to("#center", {
        attr: {
          x1: 8,
          y1: 8,
          x2: 8,
          y2: 8,
          duration: 1.4,
        },
      });

      gsap.to("#right", {
        attr: {
          x1: 2,
          y1: 15,
          x2: 14,
          y2: 2,
          duration: 1.4,
        },
      });

      setTimeout(() => {
        this.isAnimating = false;
      }, 1400);
    },
    closeMenuAnimation() {
      this.isAnimating = true;
      this.timeline.pause();

      gsap.to("#left", {
        attr: {
          x1: 2,
          y1: 4,
          x2: 2,
          y2: 12,
          duration: 1.4,
        },
      });

      gsap.to("#center", {
        attr: {
          x1: 8,
          y1: 2,
          x2: 8,
          y2: 14,
          duration: 1.4,
        },
      });

      gsap.to("#right", {
        attr: {
          x1: 14,
          y1: 5,
          x2: 14,
          y2: 11,
          duration: 1.4,
        },
      });

      setTimeout(() => {
        this.isAnimating = false;
      }, 1400);
    },
    openMobileMenuAnimation() {
      this.isAnimating = true;
      this.timeline.pause();

      gsap.to("#top", {
        attr: {
          x1: 2,
          y1: 2,
          x2: 14,
          y2: 14,
          duration: 1.4,
        },
      });

      gsap.to("#center", {
        attr: {
          x1: 8,
          y1: 8,
          x2: 8,
          y2: 8,
          duration: 1.4,
        },
      });

      gsap.to("#bottom", {
        attr: {
          x1: 2,
          y1: 14,
          x2: 14,
          y2: 2,
          duration: 1.4,
        },
      });

      setTimeout(() => {
        this.isAnimating = false;
      }, 1400);
    },
    closeMobileMenuAnimation() {
      this.isAnimating = true;
      this.timeline.pause();

      gsap.to("#top", {
        attr: {
          x1: 2,
          y1: 2,
          x2: 5,
          y2: 2,
          duration: 1.4,
        },
      });

      gsap.to("#center", {
        attr: {
          x1: 2,
          y1: 8,
          x2: 14,
          y2: 8,
          duration: 1.4,
        },
      });

      gsap.to("#bottom", {
        attr: {
          x1: 2,
          y1: 14,
          x2: 9,
          y2: 14,
          duration: 1.4,
        },
      });
      setTimeout(() => {
        this.isAnimating = false;
      }, 1400);
    },
  },
};
</script>

<style scoped lang="scss">
.menu-button {
  position: fixed;
  color: $green;
  z-index: 5;
  top: 3.125vw;
  right: 3.75vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--global--font-ui);
  font-weight: 300;
  // cursor: pointer
  cursor: none;
  user-select: none;

  @include respond-to("xs-down") {
    top: 67px;
    right: 24px;
    padding: 20px;
    margin: -20px;
    .label-container {
      display: none;
    }
  }

  svg {
    height: 19px;
    margin-right: 12px;
    overflow: hidden;
  }
}

.label-container {
  position: relative;
  min-width: 5.57em;
  height: 1.5em;
  overflow: hidden;

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    text-shadow: 0 0 0 rgba($green, 0);
    // transform: translateY(50%);

    transition: transform 0.4s ease-out, opacity 0.2s linear;

    &:before {
      content: attr(data-before);
      position: absolute;
      text-shadow: 0 0 4px rgba($green, 0.6);
      opacity: 0;
      transition: opacity 0.3s linear;
    }

    &:hover {
      // text-shadow: 0 0 4px rgba($green, 0.6);
      &:before {
        opacity: 1;
      }
    }

    &#close-label:not(.active) {
      transform: translateX(-100%);
      opacity: 0;
    }

    &#menu-label:not(.active) {
      transform: translateX(100%);
      opacity: 0;
    }
  }
}

#menu-label {
  letter-spacing: 1.2px;
}
</style>
