import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    snapGridLengthChange(swiper) {
      if( swiper.snapGrid.length != swiper.slidesGrid.length ){
        swiper.snapGrid = swiper.slidesGrid.slice(0);
      }
    }
  }
};