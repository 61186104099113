import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import Wheel from "@/mixins/Wheel";
// import PullToRefreshBehavior from "@/mixins/PullToRefreshBehavior";

import ScrollSection from "@/components/ScrollSection";

import { state } from "@/components/PullComponent/state.js";
import Device from "@/experience/Device";

function Bounds(D, bounds, top, offsetBottom) {
  var B = bounds.getBoundingClientRect();
  D.applyBounds({ top: top, left: 0, width: 0, height: B.height - offsetBottom - top });
};
export default {
  components: {
    ScrollSection,
  },
  data() {
    return {
      lockScroll: false,
      pristine: true,
      nexting: false,
      isClearingNext: false,
      isClearingPrevious: false,
      progress: 0
    };
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue >= 1) {
        if (oldValue < 1) {
          this.createNextDecorationTimeline();
        } else if (this.nextTl && oldValue < newValue) {
          this.nextTl.seek(this.offset.progress / 100, false);
        } else {
          this.clearNextDecorationTimeline();
        }
      }

      if (newValue <= -1) {
        if (oldValue > -1) {
          this.createPreviousDecorationTimeline();
        } else if (this.previousTl && oldValue > newValue) {
          this.previousTl.seek(Math.abs(this.offset.progress) / 100, false);
        } else {
          this.clearPreviousDecorationTimeline();
        }
      }
    }
  },
  mounted() {
    this.App = this.$root;
    this.nextable = state.nextabilty(this.$route.name);
    this.previousable = state.previousabilty(this.$route.name);

    state.updateProgress(0);

    this.offset = {
      y: 0,
      progress: 0,
      size: 600,
    };

    this.behaviorRegistrar = {};

    this.splitBehaviors(this.$refs);

    Object.keys(this.$refs).forEach((name) => {
      if (name.includes("container-behave")) {
        this.splitBehaviors(this.$refs[name].$refs);
      }
    });

    ScrollTrigger.addEventListener("refresh", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    ScrollTrigger.getAll().forEach((st) => st.kill(false));
  },
  unmounted() {
    if (this.wheel) this.wheel.dispose();
    ScrollTrigger.removeEventListener("refresh", this.handleResize);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    createPreviousDecorationTimeline() {
      this.isClearingPrevious = false;
      const { App } = this;
      // App.lateralNav = false;
      const value = state.previousRoute.name;
      const { decoration, gabarit } = App.openDecoration();
      const { decorationGabarit } = App.$refs;
      decorationGabarit.$el.dataset.highlight = value;
      App.highlight = value;
      if (this.previousTl) this.previousTl.kill();
      this.previousTl = gsap.timeline({
        paused: true
      });
      this.previousTl.add(App.animateDecoration({ decoration, gabarit, duration: 0.625, ease: "sine.in" }), "a");
    },
    clearPreviousDecorationTimeline() {
      if (this.previousTl && !this.isClearingPrevious) {
        this.isClearingPrevious = true;
        this.previousTl.kill();
        const { App } = this;
        const { decoration, gabarit } = App.openDecoration();
        const { decorationGabarit } = App.$refs;
        decorationGabarit.$el.dataset.highlight = 'default';
        App.highlight = 'default';
        this.previousTl = gsap.timeline({
          onComplete: () => {
            this.isClearingPrevious = false;
          }
        });
        this.previousTl.add(App.animateDecoration({ decoration, gabarit, duration: 0.625, ease: "sine.out" }), 0);
      }
    },
    createNextDecorationTimeline() {
      this.isClearingNext = false;
      const { App } = this;
      // App.lateralNav = false;
      const value = state.nextRoute.name;
      const { decoration, gabarit } = App.openDecoration();
      const { decorationGabarit } = App.$refs;
      decorationGabarit.$el.dataset.highlight = value;
      App.highlight = value;
      if (this.nextTl) this.nextTl.kill();
      this.nextTl = gsap.timeline({
        paused: true
      });
      this.nextTl.add(App.animateDecoration({ decoration, gabarit, duration: 0.625, ease: "sine.in" }), "a");
    },
    clearNextDecorationTimeline() {
      if (this.nextTl && !this.isClearingNext) {
        this.isClearingNext = true;
        this.nextTl.kill();
        const { App } = this;
        const { decoration, gabarit } = App.openDecoration();
        const { decorationGabarit } = App.$refs;
        decorationGabarit.$el.dataset.highlight = 'default';
        App.highlight = 'default';
        this.nextTl = gsap.timeline({
          onComplete: () => {
            this.isClearingNext = false;
          }
        });
        this.nextTl.add(App.animateDecoration({ decoration, gabarit, duration: 0.625, ease: "sine.out" }), 0);
      }
    },
    handleWheel({ spinY, pixelY, event }) {
      if (this.pristine) {
        this.pristine = false;
        this.handleResize();
      }
      if (this.lockScroll) {
        event.preventDefault();
        return;
      }
      const scroll = this.smoother ? this.smoother.scrollTop() : 0;
      const direction = Math.sign(spinY);
      if (scroll >= this.maxScroll && direction > 0) {
        if (this.nextable) {
          event.preventDefault();

          state.canNext = true;

          this.offset.y = this.offset.y + pixelY;

          this.killNextTweens();
          const targetProgress = Math.min(101, Math.round((this.offset.y * 100) / this.offset.size));
          this.nextTween = gsap.to(this.offset, {
            ease: "linear",
            duration: 0.1,
            progress: targetProgress,
            onUpdate: () => {
              if (this.lockScroll) {
                return;
              }
              this.updateProgress();
              this.setScrollStateComplete(direction, targetProgress);
            },
            onComplete: () => {
              if (this.lockScroll) {
                return;
              }
              if (!this.setScrollStateComplete(direction, targetProgress)) {
                this.setCancelTween();
              }
            },
          });
        }
      } else if (scroll <= 0 && direction < 0) {
        if (this.previousable) {
          event.preventDefault();

          state.canPrevious = true;

          this.offset.y = this.offset.y + pixelY;

          this.killPreviousTweens();
          const targetProgress = Math.max(-101, Math.round((this.offset.y * 100) / this.offset.size));
          this.previousTween = gsap.to(this.offset, {
            duration: 0.15,
            progress: targetProgress,
            ease: "linear",
            onUpdate: () => {
              if (this.lockScroll) {
                return;
              }
              this.updateProgress();
              this.setScrollStateComplete(direction, targetProgress);
            },
            onComplete: () => {
              if (this.lockScroll) {
                return;
              }
              if (!this.setScrollStateComplete(direction, targetProgress)) {
                this.setCancelPreviousTween();
              }
            },
          });
        }
      } else {
        if ((this.cancelNextTween && !this.cancelNextTween.isActive()) || !this.cancelNextTween) this.setCancelTween();
        if ((this.cancelPreviousTween && !this.cancelPreviousTween.isActive()) || !this.cancelPreviousTween)
          this.setCancelPreviousTween();
      }
    },
    setScrollStateComplete(direction, progress) {
      const condition = Math.abs(progress) >= 100;
      if (condition) {
        this.lockScroll = true;
        this.killTweens();
        if (direction > 0) {
          state.nextView(this.$route.name);
        } else {
          state.previousView(this.$route.name);
        }
      }
      return condition;
    },
    updateProgress() {
      const newValue = Math.round(this.offset.progress);
      this.progress = newValue;
      if (state.progress !== newValue) {
        state.updateProgress(newValue);
      }
    },
    killTweens() {
      this.killNextTweens();
      this.killPreviousTweens();
    },
    killNextTweens() {
      if (this.nextTween) this.nextTween.kill();
      if (this.cancelNextTween) this.cancelNextTween.kill();
    },
    killPreviousTweens() {
      if (this.previousTween) this.previousTween.kill();
      if (this.cancelPreviousTween) this.cancelPreviousTween.kill();
    },
    setCancelTween() {
      if (this.nextTween) this.nextTween.kill();
      if (this.cancelNextTween) this.cancelNextTween.kill();
      this.cancelNextTween = gsap.to(this.offset, {
        progress: 0,
        y: 0,
        ease: "sine.inOut",
        onUpdate: () => {
          this.updateProgress();
        },
        onComplete: () => {
          state.canNext = false;
        },
      });
    },
    setCancelPreviousTween() {
      if (this.previousTween) this.previousTween.kill();
      if (this.cancelPreviousTween) this.cancelPreviousTween.kill();
      this.cancelPreviousTween = gsap.to(this.offset, {
        progress: 0,
        y: 0,
        ease: "sine.inOut",
        onUpdate: () => {
          this.updateProgress();
        },
        onComplete: () => {
          state.canPrevious = false;
        },
      });
    },
    handleResize() {
      const { sectionContainer } = this.$refs;
      if (sectionContainer) {
        this.maxScroll = sectionContainer.scrollHeight - sectionContainer.clientHeight;
      }
      // if (this.pull2refresh) {
      //   this.pull2refresh.handleResize();
      // }
    },
    splitBehaviors($refs) {
      Object.keys($refs).forEach((name) => {
        if (name.includes("behavior")) {
          $refs[name].beforeEnter();
          this.behaviorRegistrar[name] = $refs[name];
        } else if (name.includes("array-behave")) {
          $refs[name].forEach((ref, i) => {
            ref.beforeEnter();
            this.behaviorRegistrar[`${name}-${i}`] = $refs[name][i];
          });
        }
      });
    },
    bindScrollTo() {
      if (this.$route.hash) {
        const $el = this.$el.querySelector(this.$route.hash);
        if ($el) {
          if (this.smoother) {
            this.smoother.scrollTo($el, false, "top top");
          } else {
            gsap.set(this.$refs.sectionContainer, {
              scrollTo: $el,
            });
          }
        }
      }
    },
    setSmoother() {
      if (window.innerWidth >= 835) {
        this.smoother = ScrollSmoother.create({
          wrapper: this.$el.querySelector(".section-container"),
          content: this.$el.querySelector(".section-sizer"),
          smooth: 0.8,
          smoothTouch: 0.1,
          normalizeScroll: Device.isSafari,
          paused: true,
          effects: true,
        });

        this.wheel = new Wheel({
          el: this.$el,
          onMove: this.handleWheel,
        });
      } else {
        const pullerSize = 150;

        const draggable = Draggable.create(this.$refs.sectionContainer, {
          type: "y",
          bounds: this.$el,
          edgeResistance: 1,
          inertia: true,
          snap: {
            y: (value) => {
              if (value > 0) {
                return 0;
              } else if (this.nextable && value < draggable[0].minY + pullerSize) {
                return draggable[0].minY + pullerSize;
              } else {
                return value;
              }
            }
          },
          onDrag: () => {
            ScrollTrigger.update();
          },
          onThrowUpdate: () => {
            ScrollTrigger.update();
          },
          onRelease: () => {
            if (this.previousable && draggable[0].y === pullerSize) {
              draggable[0].kill();
              state.previousView(this.$route.name);
            } else if (this.nextable && draggable[0].y === draggable[0].minY) {
              draggable[0].kill();
              state.nextView(this.$route.name);
            }
          }
        });
        Bounds(draggable[0], this.$el, this.previousable ? pullerSize : 0, this.nextable ? pullerSize : 0);
        ScrollTrigger.scrollerProxy(this.$refs.sectionContainer, {
          scrollTop(value) {
            if (arguments.length) {
              console.log("case", value);
            }
            return draggable[0].y * -1;
          },
          getBoundingClientRect() {
            return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
          }
        });
      }
    },
    bindAnimation() {
      if (!this.smoother) {
        this.setSmoother();
        this.bindScrollTo();
      }

      const $elements = Object.keys(this.behaviorRegistrar).map((key) => {
        return this.behaviorRegistrar[key].$el;
      });
      ScrollTrigger.batch($elements, {
        start: "top bottom",
        scroller: this.$el.querySelector(".section-container"),
        once: true,
        batchMax: 8,
        onEnter: (batch) => {
          batch.forEach((item, i) => {
            const idx = this.$route.hash ? 0 : i;
            if (item.classList.contains("behavior")) {
              this.behaviorRegistrar[`behavior${item.dataset.behavior}`].handleEnter(idx);
            } else {
              this.behaviorRegistrar[`array-behave${item.dataset.behavior}-${item.dataset.idx}`].handleEnter(idx);
            }
          });
        },
      });
    },
  },
};
