import gsap from "gsap";

import {
    Scene,
    Color,
    LinearFilter
} from "three";

import Device from "./Device";
import Assets from "./Assets";
import Renderer from "./Renderer";
import Cameras from "./Cameras";
import GPUCompute from "./GPUCompute";
import Fullscreen from "./Fullscreen";

export default class Experience {
    scene = new Scene();
    components = {};
    renderable = true;

    constructor(canvas) {
        this.canvas = canvas;
        this.assets = new Assets({
            onLoad: () => {
                const { assets, scene } = this;
                console.log( assets)
                this.components.fullscreen = new Fullscreen({
                    scene,
                    texture: assets.croquis
                })
            }
        });
        this.scene.background = new Color(0x06326c);
        this.renderer = new Renderer(canvas);
        this.components.cameras = new Cameras({ scene: this.scene });
        this.cameraInstance = this.components.cameras.instances['main'];
        
        this.components.GPUCompute = new GPUCompute({
            scene: this.scene,
            renderer: this.renderer.renderer
        })

        this.mounted();
    }
    sleep() {
        if(this.renderable) {
            gsap.ticker.remove(this.boundHandleRender);
            this.renderable = false;
        }
    }
    wake() {
        if(!this.renderable) {
            gsap.ticker.remove(this.boundHandleRender);
            gsap.ticker.add(this.boundHandleRender);
            this.renderable = true;
        }
    }
    mounted() {
        this.boundHandleResize = this.handleResize.bind(this);
        this.boundHandleResize();

        this.boundHandleRender = this.handleRender.bind(this);
        gsap.ticker.add(this.boundHandleRender);
        
        window.addEventListener("resize", this.boundHandleResize);
    }
    unmounted() {
        window.removeEventListener("resize", this.boundHandleResize);
        gsap.ticker.remove(this.boundHandleRender);
        this.components.cameras.dispose();
        this.renderer.dispose();
    }
    handleRender(time) {        
        this.components.cameras.render(time);
        // this.postProcess.composer.render();
  
        Object.keys(this.components).forEach((_) => {
            const component = this.components[_];
            if ('handleRender' in component) component.handleRender();
        });
        if (this.components.fullscreen) {
          const computedTexture = this.components.GPUCompute.computedTexture;
          computedTexture.minFilter = LinearFilter;
          computedTexture.magFilter = LinearFilter;
          this.components.fullscreen.material.uniforms.diffuse.value = computedTexture;
        //   this.components.fullscreen.material.needsUpdate = true
        }
  
        this.renderer.handleRender(this.scene, this.cameraInstance);
    }
    handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      Device.width = width;
      Device.height = height;
  
      this.renderer.handleResize();
    //   this.postProcess.handleResize();
  
      Object.keys(this.components).forEach((_) => {
        this.components[_].handleResize();
      });
    }
}
