<template>
  <div class="typing-text">
    <slot class="slot" />
  </div>
</template>

<script>
import gsap from "gsap";
import SplitText from "gsap/SplitText";

export default {
  name: "TypingText",
  methods: {
    createCharNode(char) {
      const cover = document.createElement("span");
      cover.classList.add("caret");
      const caption = document.createElement("div");
      caption.classList.add("caption");
      const captionHelper = document.createElement("div");
      captionHelper.classList.add("caption-helper");
      captionHelper.textContent = char;
      caption.appendChild(captionHelper)

      return {
        caption,
        cover
      }
    },
    beforeEnter() {
      this.splits = new SplitText(this.$el.children, { type: "words,chars", wordsClass: "word", charsClass: "char" });
      this.splits.chars.forEach(el => {
        const { caption, cover } = this.createCharNode(el.textContent);

        el.textContent = "";
        el.appendChild(caption);
        el.appendChild(cover);
      });
      gsap.set(this.$el.querySelectorAll(".caret"), {
        alpha: 0
      })
      gsap.set(this.$el.querySelectorAll(".caption"), {
        alpha: 0
      })
    },
    handleEnter(i) {
      if(this.tl) this.tl.kill();
      this.tl = new gsap.timeline({
        onComplete: () => {
          const eternalCaret = document.createElement("div");
          eternalCaret.classList.add('char');
          eternalCaret.classList.add('eternal-caret');
          eternalCaret.setAttribute('style', 'position:relative;display:inline-block;');
          
          const { caption, cover } = this.createCharNode(".");

          eternalCaret.appendChild(caption);
          eternalCaret.appendChild(cover);

          this.splits.words[this.splits.words.length - 1].appendChild(eternalCaret)
        }
      })
      const stagger = 0.03;
      this.tl
        .delay(i * 0.2)
        .set(this.$el.querySelectorAll(".caret"), {
          autoAlpha: 1,
          stagger,
        }, "a")
        .set(this.$el.querySelectorAll(".caret"), {
          delay: stagger,
          autoAlpha: 0,
          stagger,
        }, "a")
        .set(this.$el.querySelectorAll(".caption"), {
          delay: stagger,
          alpha: 1,
          stagger,
          ease: "expo.inOut"
        }, "a")
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes blink {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.typing-text {
  &:deep(.caret) {
    width: 0.7em;
    height: 100%;
    border: 3px solid var(--green);
    position: absolute;
    top: 0;
    left: 0;
  }
  &:deep(.char) {
    position: relative; 
  }
  &:deep(.eternal-caret) {
    color: transparent;
    .caret {
      // visibility: visible !important;
      // opacity: 1 !important;
      animation: 0.625s blink step-end infinite;
    } 
    .eternal-caret {
      display: none !important;
    }
  }
  // &:deep(.word:last-of-type .char:last-of-type) {
  //   background-color: red !important;
  //   &:after {
  //     content: "e";
  //     display: block;
  //     width: 0.7em;
  //     height: 100%;
  //     border: 3px solid var(--green);
  //     position: absolute;
  //     bottom: 0;
  //     left: 100%;
  //     font-size: inherit;
  //   }
  // }
  &:deep(.caption) {
    display: block;
    will-change: transform;
    line-height: 1;
  }
  &:deep(.caption-helper) {
    display: block;
    will-change: transform;
  }
}

</style>
