<template>
  <div class="community-container">
    <Navigation @previous="handlePrevious" @next="handleNext" :previousable="previousable" :nextable="nextable" />
    <swiper
      slides-per-view="auto"
      :centered-slides="true"
      @swiper="onSwiper"
      @slideChange="slideChange"
      :speed="628"
      :modules="modules"
      :mousewheel="{ forceToAxis: true }"
    >
      <swiper-slide v-for="{ community, src } in communities" :key="community" :class="[community]">
        <h3 class="titre-h2">{{ $t(`community.${community}`) }}</h3>
        <div class="image-holder">
          <component :is="src" />
        </div>

        <p class="texte-p3">
          {{ $t(`community.${community}Description`) }}
        </p>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref } from "vue";

import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Mentor from "@/assets/svg/person/illustration-mentor.svg";
import Formateur from "@/assets/svg/person/illustration-formateur.svg";
import PorteParole from "@/assets/svg/person/illustration-porte-paroles.svg";
import Recruteur from "@/assets/svg/person/illustration-recruteur.svg";

import Navigation from "@/components/Navigation.vue";
import { useNavigation } from "@/composable/Navigation.js";

export default {
  name: "Community",
  components: {
    Swiper,
    SwiperSlide,
    Formateur,
    Mentor,
    Recruteur,
    PorteParole,
    Navigation,
  },
  setup() {
    const { previousable, nextable, handlePrevious, handleNext, onSwiper, slideChange } = useNavigation();

    const communities = ref([
      {
        community: "mentor",
        src: "Mentor",
      },
      {
        community: "hiring",
        src: "Recruteur",
      },
      {
        community: "speaker",
        src: "PorteParole",
      },
      {
        community: "trainor",
        src: "Formateur",
      },
    ]);
    return {
      communities,

      handlePrevious,
      handleNext,
      previousable,
      nextable,
      onSwiper,
      slideChange,
      
      modules: [Mousewheel]
    };
  },
};
</script>

<style scoped lang="scss">
.community-container {
  position: relative;
  .navigation {
    @include respond-to("xs") {
      margin-right: -257%;
    }
  }
}
.swiper {
  width: 100%;
  overflow: visible;
  margin-top: 6.25vw;
  @include respond-to("xs-down") {
    margin-top: 96px;
    width: 320px;
    max-width: 100%;
  }
}
.swiper-slide {
  // margin-right: -4.78vw;
  // margin-left: -4.78vw;
  padding-right: 4.78vw;
  padding-left: 4.78vw;
  box-sizing: content-box;
  perspective: 800px;

  &:hover {
    .image-holder {
      transform: rotateY(6.25deg);
    }
  }
  @include respond-to("xs-down") {
    padding-right: 12px;
    padding-left: 12px;
  }
}
.titre-h2 {
  color: var(--light-blue);
}
.image-holder {
  background-color: var(--brand-blue);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  height: 26.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.314s ease-out;
  will-change: transform;
  @include respond-to("xs-down") {
    height: 380px;
  }
}
svg {
  width: 100%;
  height: 100%;
}
</style>
